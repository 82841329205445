import {
  backendURL,
} from '../config';
import axios from 'axios';
import { redirect } from "react-router-dom";
// import axiosRetry from 'axios-retry';

// axiosRetry(axios, {
//   retries: 5, // number of retries
//   retryDelay: axiosRetry.exponentialDelay,
//   retryCondition: (error) => {
//       // if retry condition is not specified, by default idempotent requests are retried
//       return error?.response?.status === 503;
//   },
// });
export const headerJson = () => {
  const token = localStorage.getItem("binnonet_token");
  return {
    "Content-Type": "application/json",
    Authorization: `bearer ${token}`,
    "x-access-token": token,
  };
};

export function headerFormData() {
  const token = localStorage.getItem("binnonet_token");
  return {
    "Content-Type": "multipart/form-data",
    Authorization: `bearer ${token}`,
    "x-access-token": token,
  };
}
export const fetchAPIFrom = (uri, data, props = {}, method) => {
  return new Promise((resolve, reject) => {
    axios[method](`${backendURL}/${uri}`, data, props).then((response) => {
      resolve(response.data);
    }).catch(error => {
      console.log('fetchAPIFrom',error)
      if(error?.response?.data?.message === "token is not valid!" || error?.response?.data?.message === "token is not valid") {
        localStorage.removeItem("binnonet_token");
        redirect("/dashboard/auth/login");
      }
      reject(error?.response?.data)
    });
  });
};

export const fetchAPI = async (uri) => {
  const res = await fetch(`${backendURL}/${uri}`, {next: {revalidate: 5}});
  return await res.json();
};



export async function convertImageUrlToBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
}

export const getDateTimeString = (date) =>
  `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

export const getTimeZone = () => {
  const e = String(-new Date().getTimezoneOffset() / 60);
  return e.includes('-') ? e : '+'.concat(e);
};

export function convertMinutesToHoursAndMinutes(totalMinutes) {
  // Calculate hours and remaining minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
}

export function convertHtmlToText(html) {
  const parser = new DOMParser();
  let description_without_html = parser.parseFromString(html, 'text/html')
  const textContent = description_without_html?.body?.textContent || html;

  return textContent
}