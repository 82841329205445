import React, { useEffect, useState } from 'react';
import callToActions from './model';

const CallToActionsComponent = (props) => {
  const { websiteDetails } = props;
  const [callToActionNumber, setCallToActionNumber] = useState(websiteDetails.callToActionNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setCallToActionNumber(websiteDetails.callToActionNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const CallToActionComponent = callToActions[callToActionNumber];
  return (
    <div>
      {CallToActionComponent ? <CallToActionComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setCallToActionNumber(callToActionNumber - 1 > 0 ? callToActionNumber - 1 : 1)}> - </button>
          <span>Version {callToActionNumber}</span>
          <button onClick={() => setCallToActionNumber(callToActions[callToActionNumber + 1] ? callToActionNumber + 1 : callToActionNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default CallToActionsComponent;
