export const validateFirstName = (firstName) => {
    if (!firstName) {
      return "First name is required.";
    }
    if (firstName.length < 2) {
      return "First name must be at least 2 characters long.";
    }
    return "";
  };
  
  export const validateLastName = (lastName) => {
    if (!lastName) {
      return "Last name is required.";
    }
    if (lastName.length < 2) {
      return "Last name must be at least 2 characters long.";
    }
    return "";
  };
  
  export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required.";
    }
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return "";
  };
  
  export const validatePassword = (password) => {
    if (!password) {
      return "Password is required.";
    }
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    return "";
  };

  export const signUpValidation = (firstName, lastName, email, password) => {
    const errors = {};
    errors.firstName = validateFirstName(firstName);
    errors.lastName = validateLastName(lastName);
    errors.email = validateEmail(email);
    errors.password = validatePassword(password);
    return errors;
  }

  export const signInValidation = (email, password) => {
    const errors = {};
    errors.email = validateEmail(email);
    errors.password = validatePassword(password);
    return errors;
  }