import React, { useEffect, useState } from 'react';
import productAcvtivitys from './model';

const ProductAcvtivitysComponent = (props) => {
  const { websiteDetails } = props;
  const [productAcvtivityNumber, setProductAcvtivityNumber] = useState(websiteDetails.productAcvtivityNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setProductAcvtivityNumber(websiteDetails.productAcvtivityNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const ProductAcvtivityComponent = productAcvtivitys[productAcvtivityNumber];
  return (
    <div>
      {ProductAcvtivityComponent ? <ProductAcvtivityComponent  {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setProductAcvtivityNumber(productAcvtivityNumber - 1 > 0 ? productAcvtivityNumber - 1 : 1)}> - </button>
          <span>Version {productAcvtivityNumber}</span>
          <button onClick={() => setProductAcvtivityNumber(productAcvtivitys[productAcvtivityNumber + 1] ? productAcvtivityNumber + 1 : productAcvtivityNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default ProductAcvtivitysComponent;
