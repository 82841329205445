import React, { useEffect, useState } from 'react';
import services from './model';

const ServicesComponent = (props) => {
  const { websiteDetails } = props;
  const [serviceNumber, setServiceNumber] = useState(websiteDetails.servicesNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setServiceNumber(websiteDetails.servicesNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const ServiceComponent = services[serviceNumber];
  return (
    <div>
      {ServiceComponent ? <ServiceComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setServiceNumber(serviceNumber - 1 > 0 ? serviceNumber - 1 : 1)}> - </button>
          <span>Version {serviceNumber}</span>
          <button onClick={() => setServiceNumber(services[serviceNumber + 1] ? serviceNumber + 1 : serviceNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default ServicesComponent;
