import { Flex } from "@chakra-ui/react";
import React , { useEffect } from "react";
import TableManagement from "dashboard/components/tableList";
import { columnsDataOrders } from "dashboard/pages/webpage/variable/columnsDataOrders";
import tableDataOrders from "dashboard/pages/webpage/variable/tableDataOrders.json";
import { API } from "utilities/api";
import { useOutletContext } from "react-router";
import AnalysisHeader from "dashboard/components/analysisHeader";
import StageDetails from "dashboard/pages/webpage/stageDetails";
import { toast } from "react-toastify";


export default function WebPage(props) {
  const [selectedBrand] = useOutletContext();
  const { stage } = props

  const [tableData, setTableData] = React.useState([]);
  const [columnsData, setColumnsData] = React.useState([]);
  const [stageModal, setStageModal] = React.useState();
  const [stageLayout, setStageLayout] = React.useState();
  const [noData, setNoData] = React.useState(false);
  // {
  //     title: "",
  //     value: "",
  //     icon: "",
  // }
  const [headerData, setHeaderData] = React.useState([]);
  function getPageData() {
    return API.getStageByBrandId({ brand_id: selectedBrand, stage}).then((res) => {
      if (res.entries && res.entries.length === 0) {
        setTableData(tableDataOrders)
        setColumnsData(columnsDataOrders)
        setStageModal(res.stage)
        setStageLayout(res.layout)
        setNoData(true)
      } else {
        setTableData(res.entries)
        setStageModal(res.stage)
        setStageLayout(res.layout)
        const keys = Object.keys(res.entries[0]).filter(key => !['_id', '__v', 'brand_id', 'draft'].includes(key));
        const headersArray = keys.map(key => ({ Header: key, accessor: key}));
        headersArray.push({ Header: 'ACTIONS', accessor: 'actions' })
        setColumnsData(headersArray)
        setHeaderData(res.analysis)
        setNoData(res.entries ?  true : false)
      }
    }).catch((err) => {
      console.log('getPageData',err)
    })
  }

  useEffect(() => {
    getPageData();
  }, [stage])


  const handleCreateUpdateStageData = (formData, imageFile) => {
    const newformData = new FormData();
    newformData.append('data', JSON.stringify({ ...formData, stage: stage, brand_id: selectedBrand }));
    if (imageFile && imageFile !== null) {
      newformData.append('file', imageFile);
    }
    return API.insertUpdateItemToStage(newformData).then((response) => {
      toast.success('Your data has been submitted!');
      getPageData();
    }).catch((error) => {
      toast.error(error);
    });
  };

  const handleDelete = (formData) => {
    return API.deleteItemFromStage({ ...formData, stage: stage, brand_id: selectedBrand }).then((response) => {
      toast.success('Your data has been deleted!');
      getPageData();
    }).catch((error) => {
      toast.error(error);
    });
  }


  return (
    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        <AnalysisHeader data={headerData} />
        <StageDetails stageLayout={stageLayout} stage={stage} selectedBrand={selectedBrand}/>
        { noData && <TableManagement
          getPageData={getPageData}
          tableData={tableData}
          columnsData={columnsData}
          name={stage}
          popupModal={stageModal}
          selectedBrand={selectedBrand}
          handleCreate={handleCreateUpdateStageData}
          handleDelete={handleDelete}
          handleEdit={handleCreateUpdateStageData}
        />
      }
          </Flex>
          </Flex>
  );
}
