import React from "react";
import { Grid, Icon, Button, Typography, Box, Container } from "@mui/material";

const Marketing3 = () => {
  const details = [
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed",
    "Eirmod tempor invidunt ut labore et dolore magna aliquyam",
    "At vero eos et accusam et justo duo dolores et ea rebum",
  ];

  return (
    <Box component="section" sx={{ py: 8 }} id="marketing3">
      <Container>
        <Grid container spacing={10} justifyContent="space-between" alignItems="center">
          <Grid item sm={6} xs={12}>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
                marginBottom: 4,
              }}
            >
              For a Better Marketing
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ my: 4, maxWidth: 400 }}
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita
            </Typography>

            {details.map((detail, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Icon sx={{ mr: 2, color: "primary.main" }}>done</Icon>
                <Typography variant="body2">
                  {detail}
                </Typography>
              </Box>
            ))}

            <Box mt={4}>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: 1,
                  px: 7,
                  "&:hover": {
                    backgroundColor: "primary.main",
                    color: "white",
                  },
                }}
              >
                CLICK TO VIEW MORE
              </Button>
            </Box>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box
              component="img"
              src="/assets/images/laptop-4.png"
              alt="Laptop"
              sx={{ width: "100%", display: "block" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Marketing3;
