
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
// Assets
import banner from "dashboard/assets/img/auth/banner.png";
import profile from "dashboard/assets/img/crm/vbz.png";
import React from "react";

import Info from "dashboard/views/admin/main/profile/settings/components/Info";
import Password from "dashboard/views/admin/main/profile/settings/components/Password";
import Profile from "dashboard/views/admin/main/profile/settings/components/Profile";
import Socials from "dashboard/views/admin/main/profile/settings/components/Socials";

export default function Settings() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {/* Column Left */}
        <Flex direction='column'>
          <Profile name='Vlad Mihalache' avatar={profile} banner={banner} />
          <Info />
        </Flex>
        {/* Column Right */}
        <Flex direction='column'>
          <Socials />
          <Password />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
