// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
  ChakraProvider,
} from '@chakra-ui/react';
import Footer from 'dashboard/components/footer/FooterAdmin';
// Layout components
import Navbar from 'dashboard/components/navbar/NavbarAdmin';
import Sidebar from 'dashboard/components/sidebar/Sidebar';
import { SidebarContext } from 'dashboard/contexts/SidebarContext';
import { useEffect, useState } from 'react';
import { Route, Routes, Navigate, Outlet, useNavigate } from 'react-router-dom';
import routes from 'pages/routes';
import 'dashboard/assets/css/App.css';
import UnAuthorized from 'dashboard/views/admin/main/others/unAuthorized';
import { API } from 'utilities/api';
import { toast } from 'react-toastify';
import CreateModal from 'dashboard/components/brand/create';
// Custom Chakra theme
export default function Dashboard(props) {
  const { theme, setTheme, authentication } = props
  const router = routes(props).routes;
  router.shift();
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const navigateRedirect = useNavigate();
  const [ token ] = useState(localStorage.getItem('binnonet_token'));

  const fetchBrands = () => {
    API.getBrandsFromAccessListbyUserId().then(res => {
      setBrandList(res)
      setValidToken(true);
      setSelectedBrand(res[0]?.brand_id._id);
    }).catch(err => {
      // localStorage.removeItem('binnonet_token');
      // navigateRedirect("/dashboard/auth/login");
      // setValidToken(false);
      toast.error(err)
    })
  }
  // token validation
  useEffect(() => {
    fetchBrands();
    if (token) {
      setValidToken(true);
    } else if (authentication) {
      navigateRedirect("/dashboard/auth/login");
      setValidToken(true);
    } else {
      navigateRedirect("/dashboard/auth/login");
    }
  }, [])
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (routes) => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].children);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].children);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/admin') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.children);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');
  return (
    <>

      <ChakraProvider theme={theme}>
        {validToken ? (
          <Box bg={bg} h="100vh" w="100vw">
            <SidebarContext.Provider
              value={{
                toggleSidebar,
                setToggleSidebar,
              }}
            >
              {!authentication ? (<> <Sidebar
                setSelectedBrand={setSelectedBrand}
                selectedBrand={selectedBrand}
                brandList={brandList}
                hovered={hovered}
                setHovered={setHovered}
                mini={mini}
                routes={router}
                display="none"
                {...rest}
              />
                <Box
                  float="right"
                  minHeight="100vh"
                  height="100%"
                  overflow="auto"
                  position="relative"
                  maxHeight="100%"
                  w={
                    mini === false
                      ? { base: '100%', xl: 'calc( 100% - 290px )' }
                      : mini === true && hovered === true
                        ? { base: '100%', xl: 'calc( 100% - 290px )' }
                        : { base: '100%', xl: 'calc( 100% - 120px )' }
                  }
                  maxWidth={
                    mini === false
                      ? { base: '100%', xl: 'calc( 100% - 290px )' }
                      : mini === true && hovered === true
                        ? { base: '100%', xl: 'calc( 100% - 290px )' }
                        : { base: '100%', xl: 'calc( 100% - 120px )' }
                  }
                  transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                  transitionDuration=".2s, .2s, .35s"
                  transitionProperty="top, bottom, width"
                  transitionTimingFunction="linear, linear, ease"
                >
                  {brandList.length > 0 ? (
                    <>
                      <Portal>
                        <Box>
                          <Navbar
                            hovered={hovered}
                            setMini={setMini}
                            mini={mini}
                            onOpen={onOpen}
                            logoText={'Binnonet Dashboard'}
                            brandText={getActiveRoute(router)}
                            secondary={getActiveNavbar(router)}
                            theme={props.theme}
                            setTheme={props.setTheme}
                            fixed={fixed}
                            brandList={brandList}
                            selectedBrand={selectedBrand}
                            setSelectedBrand={setSelectedBrand}
                            fetchBrands={fetchBrands}
                            {...rest}
                          />
                        </Box>
                      </Portal>
                      {getRoute() ? (
                        <Box
                          mx="auto"
                          p={{ base: '20px', md: '30px' }}
                          pe="20px"
                          minH="100vh"
                          pt="50px"
                        >
                          <Routes>
                            {getRoutes(router)}
                            <Route
                              path="/"
                              element={<Navigate to="/dashboard/brand/overview" replace />}
                            />
                          </Routes>
                          <Outlet selectedBrand={selectedBrand} context={[selectedBrand, setSelectedBrand]}  />
                        </Box>
                      ) : null}
                    </>
                  ) : ( 
                  <CreateModal add={true} />
                  )}
                  <Box>
                    <Footer />
                  </Box>
                </Box> </>) : (<>
                  <Portal>
                    <Box>
                      <Navbar
                        hovered={hovered}
                        setMini={setMini}
                        mini={mini}
                        onOpen={onOpen}
                        logoText={'Binnonet Dashboard'}
                        brandText={getActiveRoute(router)}
                        secondary={getActiveNavbar(router)}
                        theme={theme}
                        setTheme={setTheme}
                        fixed={fixed}
                        {...rest}
                      />
                    </Box>
                  </Portal>
                  {getRoute() ? (
                    <Box
                      mx="auto"
                      p={{ base: '20px', md: '30px' }}
                      pe="20px"
                      minH="100vh"
                      pt="50px"
                    >

                      <Outlet selectedBrand={selectedBrand} />
                    </Box>
                  ) : null}
                  <Box>
                    <Footer />
                  </Box>
                </>)}
            </SidebarContext.Provider>
          </Box>
        ) : (<UnAuthorized />)}
      </ChakraProvider>

    </>
  );
}
