import React, { useEffect, useState } from 'react';
import stories from './model';

const StoriesComponent = (props) => {
  const { websiteDetails } = props;
  const [storieNumber, setStorieNumber] = useState(websiteDetails.storieNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setStorieNumber(websiteDetails.storieNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const StorieComponent = stories[storieNumber];
  return (
    <div>
      {StorieComponent ? <StorieComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setStorieNumber(storieNumber - 1 > 0 ? storieNumber - 1 : 1)}> - </button>
          <span>Version {storieNumber}</span>
          <button onClick={() => setStorieNumber(stories[storieNumber + 1] ? storieNumber + 1 : storieNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default StoriesComponent;
