import Header1 from "./Header1";
import Header2 from "./Header2";
import Header3 from "./Header3";
import Header4 from "./Header4";
import Header5 from "./Header5";
import Header6 from "./Header6";
import Header7 from "./Header7";
import Header8 from "./Header8";
import Header9 from "./Header9";
import Header10 from "./Header10";
import Header11 from "./Header11";
import Header12 from "./Header12";
import Header13 from "./Header13";


const headers = {
    1: Header1,
    2: Header2,
    3: Header3,
    4: Header4,
    5: Header5,
    6: Header6,
    7: Header7,
    8: Header8,
    9: Header9,
    10: Header10,
    11: Header11,
    12: Header12,
    13: Header13
  };
  
  export default headers;