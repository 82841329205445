import CallToAction1 from "./CallToAction1";
import CallToAction2 from "./CallToAction2";
import CallToAction3 from "./CallToAction3";

const callToActions = {
    1: CallToAction1,
    2: CallToAction2,
    3: CallToAction3
};

export default callToActions;