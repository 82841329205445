import React from "react";
import { useOutletContext } from "react-router";

const Landing = (props) => {
  const { websiteDetails, componentsOrder, componentsMap } = props;
  const [landingData, layoutData] = useOutletContext();
  const renderComponent = (Component, number, landingDetails, componentKey, layoutDetails) => {
    if (!Component || number <= 0) return null;
    const data = layoutDetails.filter(items => items.stage_name.includes(componentKey === "stories" ?  "ourstory" : componentKey === 'clients' ? 'ourtrustedclients' : componentKey));
    return <Component key={Math.random()} websiteDetails={websiteDetails} landingDetails={landingDetails} componentKey={componentKey} layoutDetails={data[0]}/>;
  };

  return (
    <div className="landing">
      {componentsOrder.map((componentKey) => {
        const Component = componentsMap[componentKey];
        const numberKey = `${componentKey}Number`;
        return renderComponent(Component, websiteDetails[numberKey], landingData[componentKey === "stories" ?  "ourstory" : componentKey === 'clients' ? 'ourtrustedclients' : componentKey], componentKey, layoutData);
      })}
    </div>
  );
};

export default Landing;
