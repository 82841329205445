import React from "react";
import {
  Grid,
  Card,
  IconButton,
  Divider,
  Icon,
  Button,
  Typography,
  Container,
  Box,
} from "@mui/material";

import EuroIcon from "../common/icons/EuroIcon";
import StarterIcon from "../common/icons/StarterIcon";
import TieIcon from "../common/icons/TieIcon";
import CompanyIcon from "../common/icons/CompanyIcon";

const Pricing2 = () => {
  const featureList = [
    "Full Access",
    "Source Files",
    "Code Upload",
    "Enhanced Security",
    "Free Installment",
  ];

  const planList = [
    {
      title: "Free",
      icon: EuroIcon,
      price: 0,
    },
    {
      title: "Starter",
      icon: StarterIcon,
      price: 19,
    },
    {
      title: "Business",
      icon: TieIcon,
      price: 39,
    },
    {
      title: "Ultimate",
      icon: CompanyIcon,
      price: 59,
    },
  ];

  return (
    <Box component="section" sx={{ py: 8, position: "relative" }}>
      <Container>
        <Box textAlign="center" mb={8}>
          <Typography variant="h1" sx={{ fontSize: "2.75rem", fontWeight: "normal", my: 4 }}>
            Our Services
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
          </Typography>
        </Box>

        <Box
          sx={{
            position: "relative",
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              height: "60%",
              left: "-1000px",
              right: "-1000px",
              top: "20%",
              background: (theme) => theme.palette.primary.main,
              opacity: 0.1,
              zIndex: -1,
            },
          }}
        >
          <Grid container spacing={2}>
            {planList.map((plan, ind) => (
              <Grid key={ind} item lg={3} md={3} sm={6} xs={12}>
                <Card
                  elevation={2}
                  sx={{
                    p: 4,
                    border: 1,
                    borderColor: "background.default",
                    borderRadius: 1,
                    zIndex: 1,
                    transition: "all 400ms",
                    "&:hover": {
                      borderColor: "primary.main",
                      boxShadow: 6,
                      "& .MuiButtonBase-root": {
                        backgroundColor: "primary.main",
                        "& .MuiSvgIcon-root": {
                          fill: "primary.contrastText",
                        },
                      },
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" mb={6}>
                    <IconButton
                      sx={{
                        mr: 2,
                        p: 1,
                        backgroundColor: "primary.light",
                        borderRadius: 1,
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      <plan.icon color="primary" fontSize="large" />
                    </IconButton>
                    <Box>
                      <Typography variant="h5" color="primary" gutterBottom>
                        {plan.title}
                      </Typography>
                      <Typography variant="body1">
                        $ <Box component="span" sx={{ fontSize: "2rem", fontWeight: "medium" }}>{plan.price}</Box>/mo
                      </Typography>
                    </Box>
                  </Box>

                  <Divider />

                  <Box my={4}>
                    {featureList.map((item, ind) => (
                      <Box key={ind} display="flex" alignItems="center" my={2}>
                        <Icon sx={{ mr: 2 }} color="primary">done</Icon>
                        <Typography variant="body2">{item}</Typography>
                      </Box>
                    ))}
                  </Box>

                  <Button
                    sx={{ borderRadius: 2, px: 6 }}
                    variant="contained"
                    color="primary"
                  >
                    Buy Now
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing2;
