import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Landing from "./Landing";
import NotFound from "./NotFound";
import Blogs from "./Blog";
import ErrorPage from "./Error";
import withProps from "./withProps";
import BlogPage from "../components/blogPage";

import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome, MdLock, MdOutlineShoppingCart } from 'react-icons/md';

// Dashboards Layouts
// import AuthLayout from 'dashboard/layouts/auth';
import AdminLayout from 'dashboard/layouts/admin';
// import RTLLayout from 'dashboard/layouts/rtl';

// Admin Imports
import DashboardsDefault from 'dashboard/views/admin/dashboards/default/index.jsx';
// import DashboardsRTLDefault from 'dashboard/views/admin/dashboards/rtl/index.jsx';
import DashboardsCarInterface from 'dashboard/views/admin/dashboards/carInterface/index.jsx';
import DashboardsSmartHome from 'dashboard/views/admin/dashboards/smartHome/index.jsx';

import Pages from 'dashboard/pages';
// NFT Imports
import NFTMarketplace from 'dashboard/views/admin/nfts/marketplace/index.jsx';
import NFTPage from 'dashboard/views/admin/nfts/page/index.jsx';
import NFTCollection from 'dashboard/views/admin/nfts/collection/index.jsx';
import NFTProfile from 'dashboard/views/admin/nfts/profile/index.jsx';

// Main Imports
import AccountBilling from 'dashboard/views/admin/main/account/billing/index.jsx';
// import AccountApplications from 'dashboard/views/admin/main/account/application/index.jsx';
import AccountInvoice from 'dashboard/views/admin/main/account/invoice/index.jsx';
import AccountSettings from 'dashboard/views/admin/main/account/settings/index.jsx';
import AccountAllCourses from 'dashboard/views/admin/main/account/courses/index.jsx';
import AccountCoursePage from 'dashboard/views/admin/main/account/coursePage/index.jsx';

import UserNew from 'dashboard/views/admin/main/users/newUser/index.jsx';
import UsersOverview from 'dashboard/views/admin/main/users/overview/index.jsx';
import UsersReports from 'dashboard/views/admin/main/users/reports/index.jsx';

import ProfileSettings from 'dashboard/views/admin/main/profile/settings/index.jsx';
import ProfileOverview from 'dashboard/views/admin/main/profile/overview/index.jsx';
import ProfileNewsfeed from 'dashboard/views/admin/main/profile/newsfeed/index.jsx';

import ApplicationsKanban from 'dashboard/views/admin/main/applications/kanban/index.jsx';
import ApplicationsDataTables from 'dashboard/views/admin/main/applications/dataTables/index.jsx';
import ApplicationsCalendar from 'dashboard/views/admin/main/applications/calendar/index.jsx';

import EcommerceNewProduct from 'dashboard/views/admin/main/ecommerce/newProduct/index.jsx';
import EcommerceProductOverview from 'dashboard/views/admin/main/ecommerce/overviewProduct/index.jsx';
import EcommerceProductSettings from 'dashboard/views/admin/main/ecommerce/settingsProduct/index.jsx';
import EcommerceProductPage from 'dashboard/views/admin/main/ecommerce/pageProduct/index.jsx';
import EcommerceOrderList from 'dashboard/views/admin/main/ecommerce/orderList/index.jsx';
import EcommerceOrderDetails from 'dashboard/views/admin/main/ecommerce/orderDetails/index.jsx';
import EcommerceReferrals from 'dashboard/views/admin/main/ecommerce/referrals/index.jsx';
// Pages Imports
import WebPage from 'dashboard/pages/webpage';

// Others
import OthersNotifications from 'dashboard/views/admin/main/others/notifications/index.jsx';
import OthersPricing from 'dashboard/views/admin/main/others/pricing/index.jsx';
import OthersError from 'dashboard/views/admin/main/others/404/index.jsx';
import Messages from 'dashboard/views/admin/main/others/messages/index.jsx';

// Auth Imports
import ForgotPasswordCentered from 'dashboard/views/auth/forgotPassword/ForgotPasswordCentered.jsx';
// import ForgotPasswordDefault from 'dashboard/views/auth/forgotPassword/ForgotPasswordDefault.jsx';
// import LockCentered from 'dashboard/views/auth/lock/LockCentered.jsx';
// import LockDefault from 'dashboard/views/auth/lock/LockDefault.jsx';
import SignInCentered from 'dashboard/views/auth/signIn/SignInCentered.jsx';
// import SignInDefault from 'dashboard/views/auth/signIn/SignInDefault.jsx';
import SignUpCentered from 'dashboard/views/auth/signUp/SignUpCentered.jsx';
// import SignUpDefault from 'dashboard/views/auth/signUp/SignUpDefault.jsx';
// import VerificationCentered from 'dashboard/views/auth/verification/VerificationCentered.jsx';
// import VerificationDefault from 'dashboard/views/auth/verification/VerificationDefault.jsx';

const routes = (props) => createBrowserRouter([
  {
    path: "/",
    name: "Home",
    element: withProps(Layout, props),
    errorElement: <ErrorPage />,
    children: [
      { name: 'Home', index: true, element: withProps(Landing, props),},
      { name: 'blog', index: true, path: "blog/:slug", element: withProps(BlogPage, props),},
      { name: 'blogs', index: true, path: "blog", element: withProps(Blogs, props),},
      { path: "*", element: <NotFound /> }
    ]
  },
  // --- Dashboards ---
  {
    name: 'Brand Manager',
    path: 'dashboard/brand',
    element: withProps(AdminLayout, props),
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    children: [
      {
        name: 'Settings',
        layout: '/dashboard/brand/',
        path: 'settings',
        element: <Pages.BrandPages.BrandSetting />,
      },
      {
        name: 'Overview',
        layout: '/dashboard/brand/',
        path: 'overview',
        element: <DashboardsDefault />,
      },
      {
        name: 'Billing',
        layout: '/dashboard/brand/',
        path: 'billing',
        element: <AccountBilling />,
      },
      {
        name: 'IAM',
        layout: '/dashboard/brand/',
        path: 'iam',
        element: <Pages.BrandPages.IAMComponent />,
      },
      {
        name: 'Mail manager',
        layout: '/dashboard/brand/',
        path: 'mail',
        element: <DashboardsDefault />,
      },
      {
        name: 'Marketing',
        layout: '/dashboard/brand/',
        path: 'marketing',
        element: <DashboardsDefault />,
      },
      {
        name: 'Invoice manager',
        layout: '/dashboard/brand/',
        path: 'invoice',
        element: <AccountInvoice />,
      }
    ],
  },
  // --- Webpage ---
  {
    name: 'Web page manager',
    path: 'dashboard/webpage',
    element: withProps(AdminLayout, props),
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    children: [
      {
        name: 'Blog Posts',
        layout: '/dashboard/webpage/',
        path: 'blogs',
        exact: false,
        element: <WebPage stage={"blogs"} />,
      },
      {
        name: 'Services List',
        layout: '/dashboard/webpage/',
        path: 'services',
        exact: false,
        element: <WebPage stage={"services"} />,
      },
      {
        name: 'Summary',
        layout: '/dashboard/webpage/',
        path: 'summary',
        exact: false,
        element: <WebPage stage={"summary"} />,
      },
      {
        name: 'About us',
        layout: '/dashboard/webpage/',
        path: 'aboutus',
        exact: false,
        element: <WebPage stage={"aboutus"} />,
      },
      {
        name: 'Our Team',
        layout: '/dashboard/webpage/',
        path: 'ourteam',
        exact: false,
        element: <WebPage stage={"ourteam"} />,
      },
      {
        name: 'Our Story',
        layout: '/dashboard/webpage/',
        path: 'ourstory',
        exact: false,
        element: <WebPage stage={"ourstory"} />,
      },
      {
        name: 'Our Trusted Clients',
        layout: '/dashboard/webpage/',
        path: 'ourtrustedclients',
        exact: false,
        element: <WebPage stage={"ourtrustedclients"} />,
      },
      {
        name: 'Our Partners',
        layout: '/dashboard/webpage/',
        path: 'ourpartners',
        exact: false,
        element: <WebPage stage={"ourpartners"} />,
      },
      {
        name: 'Recommendations',
        layout: '/dashboard/webpage/',
        path: 'recommendations',
        exact: false,
        element: <WebPage stage={"recommendations"} />,
      },
      {
        name: 'News',
        layout: '/dashboard/webpage/',
        path: 'news',
        exact: false,
        element: <WebPage stage={"news"} />,
      },
      {
        name: 'Projects',
        layout: '/dashboard/webpage/',
        path: 'projects',
        exact: false,
        element: <WebPage stage={"projects"} />,
      },
    ],
  },

  // --- Ecommerce ---
  {
    name: 'Ecommerce',
    path: 'dashboard/ecommerce',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: withProps(AdminLayout, props),
    collapse: true,
    children: [
      {
        name: 'New Product',
        layout: '/dashboard/ecommerce/',
        path: 'new-prodcut',
        exact: false,
        element: <EcommerceNewProduct />,
      },
      {
        name: 'Product Overview',
        layout: '/dashboard/ecommerce/',
        path: 'product-overview',
        exact: false,
        element: <EcommerceProductOverview />,
      },
      {
        name: 'Product Settings',
        layout: '/dashboard/ecommerce/',
        path: 'settings',
        exact: false,
        element: <EcommerceProductSettings />,
      },
      {
        name: 'Product Page',
        layout: '/dashboard/ecommerce/',
        path: 'page-example',
        exact: false,
        element: <EcommerceProductPage />,
      },
      {
        name: 'Order List',
        layout: '/dashboard/ecommerce/',
        path: 'order-list',
        exact: false,
        element: <EcommerceOrderList />,
      },
      {
        name: 'Order Details',
        layout: '/dashboard/ecommerce/',
        path: 'order-details',
        exact: false,
        element: <EcommerceOrderDetails />,
      },
      {
        name: 'Referrals',
        layout: '/dashboard/ecommerce/',
        path: 'referrals',
        exact: false,
        element: <EcommerceReferrals />,
      },
    ],
  },
  // // // --- NFTs ---
  {
    name: 'NFTs',
    path: 'dashboard/nfts',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: withProps(AdminLayout, props),
    collapse: true,
    children: [
      {
        name: 'Marketplace',
        layout: '/dashboard/nfts/',
        path: 'marketplace',
        element: <NFTMarketplace />,
        secondary: true,
      },
      {
        name: 'Collection',
        layout: '/dashboard/nfts/',
        path: 'collection',
        element: <NFTCollection />,
        secondary: true,
      },
      {
        name: 'NFT Page',
        layout: '/dashboard/nfts/',
        path: 'page',
        element: <NFTPage />,
        secondary: true,
      },
      {
        name: 'Profile',
        layout: '/dashboard/nfts/',
        path: 'profile',
        element: <NFTProfile />,
        secondary: true,
      },
    ],
  },

  // --- Smart Home ---
  {
    name: 'Smart Home',
    path: 'dashboard/smart',
    element: withProps(AdminLayout, props),
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    children: [
      {
        name: 'Car Interface',
        layout: '/dashboard/smart/',
        path: 'car',
        element: <DashboardsCarInterface />,
      },
      {
        name: 'Smart Home',
        layout: '/dashboard/smart/',
        path: 'home',
        element: <DashboardsSmartHome />,
      },
    ],
  },

  // // // --- Account pages ---
  {
    name: 'User Account',
    path: 'dashboard/account',
    element: withProps(AdminLayout, props),
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    children: [
      {
        name: 'Profile Overview',
        layout: '/dashboard/account/',
        path: 'overview',
        exact: false,
        element: <ProfileOverview />,
      },
      {
        name: 'Profile Settings',
        layout: '/dashboard/account/',
        path: 'settings',
        exact: false,
        element: <AccountSettings />,
      },
      
    ],
  },

  // --- Other pages
  {
    name: 'Other Pages',
    path: 'dashboard/other',
    element: withProps(AdminLayout, props),
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    children: [
      {
        name: 'All Courses',
        layout: '/dashboard/other/',
        path: 'all-courses',
        secondary: true,
        element: <AccountAllCourses />,
      },
      {
        name: 'Course Page',
        layout: '/dashboard/other/',
        path: 'course-page',
        secondary: true,
        element: <AccountCoursePage />,
      },
      {
        name: 'New User',
        layout: '/dashboard/other/',
        path: 'new-user',
        exact: false,
        element: <UserNew />,
      },
      {
        name: 'Users Overview',
        layout: '/dashboard/other/',
        path: 'users-overview',
        exact: false,
        element: <UsersOverview />,
      },
      {
        name: 'Users Reports',
        layout: '/dashboard/other/',
        path: 'users-reports',
        exact: false,
        element: <UsersReports />,
      },
      {
        name: 'Kanban',
        layout: '/dashboard/other/',
        path: 'kanban',
        exact: false,
        element: <ApplicationsKanban />,
      },
      {
        name: 'Data Tables',
        layout: '/dashboard/other/',
        path: 'data-tables',
        exact: false,
        element: <ApplicationsDataTables />,
      },
      {
        name: 'Calendar',
        layout: '/dashboard/other/',
        path: 'calendar',
        exact: false,
        element: <ApplicationsCalendar />,
      },
      {
        name: 'Profile Overview',
        layout: '/dashboard/other/',
        path: 'overview',
        exact: false,
        element: <ProfileOverview />,
      },
      {
        name: 'Profile Settings',
        layout: '/dashboard/other/',
        path: 'settings',
        exact: false,
        element: <ProfileSettings />,
      },
      {
        name: 'News Feed',
        layout: '/dashboard/other/',
        path: 'newsfeed',
        exact: false,
        element: <ProfileNewsfeed />,
      },
      {
        name: 'Notifications',
        layout: '/dashboard/other/',
        path: 'notifications',
        exact: false,
        element: <OthersNotifications />,
      },
      {
        name: 'Pricing',
        layout: '/dashboard/other/',
        path: 'pricing',
        exact: false,
        element: <OthersPricing />,
      },
      {
        name: '404',
        layout: '/dashboard/other/',
        path: '404',
        exact: false,
        element: <OthersError />,
      },
      {
        name: 'Messages',
        layout: '/dashboard/other/',
        path: 'messages',
        exact: false,
        element: <Messages />,
      },
      {
        name: 'News Feed',
        layout: '/dashboard/other/',
        path: 'newsfeed',
        exact: false,
        element: <ProfileNewsfeed />,
      },
    ],
  },
  // // --- Authentication ---
  {
    name: 'Authentication',
    path: 'dashboard/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    element: withProps(AdminLayout, {authentication: true , ...props}),
    collapse: true,
    hide: true,
    children: [
      // {
          //   name: 'Default',
          //   layout: 'dashboard/auth/',
          //   path: 'login',
          //   element: <SignInDefault />,
          // },
          {
            name: 'Login',
            layout: '/dashboard/auth/',
            path: 'login',
            element: <SignInCentered />,
          },
       // {
          //   name: 'Default',
          //   layout: '/dashboard/auth/',
          //   path: 'default',
          //   element: <SignUpDefault />,
          // },
          {
            name: 'Sign Up',
            layout: '/dashboard/auth/',
            path: 'signup',
            element: <SignUpCentered />,
          },
           // {
          //   name: 'Reset Password',
          //   layout: '/dashboard/auth/',
          //   path: 'resetpassword',
          //   element: <ForgotPasswordDefault />,
          // },
          {
            name: 'Reset Password',
            layout: '/dashboard/auth/',
            path: 'resetpassword',
            element: <ForgotPasswordCentered />,
          },
      // --- Verification ---
      // {
      //   name: 'Verification',
      //   path: 'verification',
      //   collapse: true,
      //   children: [
      //     {
      //       name: 'Verification',
      //       layout: '/dashboard/auth/',
      //       path: 'verification',
      //       element: <VerificationDefault />,
      //     },
      //     {
      //       name: 'Verification',
      //       layout: '/dashboard/auth/',
      //       path: 'verification',
      //       element: <VerificationCentered />,
      //     },
      //   ],
      // },
      // --- Lock ---
      // {
      //   name: 'Lock',
      //   path: 'lock',
      //   collapse: true,
      //   children: [
      //     {
      //       name: 'Lock',
      //       layout: '/dashboard/auth/',
      //       path: 'lock',
      //       element: <LockDefault />,
      //     },
      //     {
      //       name: 'Lock',
      //       layout: '/dashboard/auth/',
      //       path: 'lock',
      //       element: <LockCentered />,
      //     },
      //   ],
      // },
      // --- Forgot Password ---
    ],
  },
]);

export default routes;
