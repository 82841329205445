import React, { useEffect, useState } from 'react';
import subscriptions from './model';

const SubscriptionsComponent = (props) => {
  const { websiteDetails } = props;
  const [subscriptionNumber, setSubscriptionNumber] = useState(websiteDetails.subscriptionNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setSubscriptionNumber(websiteDetails.subscriptionNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const SubscriptionComponent = subscriptions[subscriptionNumber];
  return (
    <div>
      {SubscriptionComponent ? <SubscriptionComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setSubscriptionNumber(subscriptionNumber - 1 > 0 ? subscriptionNumber - 1 : 1)}> - </button>
          <span>Version {subscriptionNumber}</span>
          <button onClick={() => setSubscriptionNumber(subscriptions[subscriptionNumber + 1] ? subscriptionNumber + 1 : subscriptionNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionsComponent;
