import React, { useEffect, useState } from 'react';
import projects from './model';

const ProjectsComponent = (props) => {
  const { websiteDetails } = props;
  const [workNumber, setWorkNumber] = useState(websiteDetails.workNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setWorkNumber(websiteDetails.workNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const WorkComponent = projects[workNumber];
  return (
    <div>
      {WorkComponent ? <WorkComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setWorkNumber(workNumber - 1 > 0 ? workNumber - 1 : 1)}> - </button>
          <span>Version {workNumber}</span>
          <button onClick={() => setWorkNumber(projects[workNumber + 1] ? workNumber + 1 : workNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default ProjectsComponent;
