import React, { useEffect, useState } from 'react';
import faqs from './model';

const FaqsComponent = (props) => {
  const { websiteDetails } = props;
  const [faqNumber, setFaqNumber] = useState(websiteDetails.faqNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setFaqNumber(websiteDetails.faqNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const FaqComponent = faqs[faqNumber];
  return (
    <div>
      {FaqComponent ? <FaqComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setFaqNumber(faqNumber - 1 > 0 ? faqNumber - 1 : 1)}> - </button>
          <span>Version {faqNumber}</span>
          <button onClick={() => setFaqNumber(faqs[faqNumber + 1] ? faqNumber + 1 : faqNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default FaqsComponent;
