import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
} from "@mui/material";

const FAQ1 = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const categoryList = [
    {
      category: "About Our Product",
      faqs: [
        {
          question: "What is our product?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
        {
          question: "How does it work?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "License",
      faqs: [
        {
          question: "What is the license policy?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
        {
          question: "How to get a license?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "Terms of Use",
      faqs: [
        {
          question: "What are the terms of use?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "Author Fee",
      faqs: [
        {
          question: "What is the author fee?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "Support",
      faqs: [
        {
          question: "How to get support?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "Privacy",
      faqs: [
        {
          question: "What is your privacy policy?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "Our Advisor",
      faqs: [
        {
          question: "Who is our advisor?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "NDA Terms",
      faqs: [
        {
          question: "What are the NDA terms?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
    {
      category: "Support Policy",
      faqs: [
        {
          question: "What is the support policy?",
          answer:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
        },
      ],
    },
  ];

  return (
    <Box component="section" sx={{ py: 8 }} id="faq">
      <Container>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.light
                  : theme.palette.primary.dark,
              marginBottom: 2,
            }}
          >
            FAQs
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ maxWidth: 400, mx: "auto", mb: 4 }}
          >
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item md={3} sm={4} xs={12}>
            <Box>
              {categoryList.map((category, ind) => (
                <Box
                  key={ind}
                  sx={{
                    px: 4,
                    py: 2,
                    mb: 3,
                    textAlign: "center",
                    borderRadius: 1,
                    cursor: "pointer",
                    backgroundColor: (theme) =>
                      tabIndex === ind
                        ? theme.palette.primary.main
                        : theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0, 0, 0, 0.05)",
                    color: (theme) =>
                      tabIndex === ind
                        ? "white"
                        : theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.7)"
                        : "inherit",
                    "&:hover": {
                      backgroundColor: (theme) =>
                        theme.palette.primary.main,
                      color: "white",
                    },
                  }}
                  onClick={() => setTabIndex(ind)}
                >
                  {category.category}
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={9} sm={8} xs={12}>
            {categoryList[tabIndex].faqs.map((faq, ind) => (
              <Accordion
                key={ind}
                sx={{
                  borderRadius: 1,
                  mb: 2,
                  boxShadow: expandedIndex === ind ? 3 : 1,
                }}
                onChange={(e, expanded) =>
                  expanded ? setExpandedIndex(ind) : setExpandedIndex(null)
                }
                expanded={expandedIndex === ind}
              >
                <AccordionSummary
                  sx={{
                    backgroundColor: (theme) =>
                      expandedIndex === ind
                        ? theme.palette.primary.main
                        : theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0, 0, 0, 0.05)",
                    color: (theme) =>
                      expandedIndex === ind
                        ? "white"
                        : theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.7)"
                        : "inherit",
                    "&:hover": {
                      backgroundColor: (theme) =>
                        theme.palette.primary.main,
                      color: "white",
                    },
                  }}
                >
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQ1;
