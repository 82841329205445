import React from "react";
import {
  Grid,
  Card,
  Button,
  Typography,
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Projects1 = (props) => {
  const { landingDetails, layoutDetails } = props;
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const getVisibleItems = (startIndex, itemsPerPage) => {
    const visibleItems = [];
    for (let i = 0; i < itemsPerPage; i++) {
      const index = (startIndex + i) % landingDetails?.length;
      visibleItems.push(landingDetails[index]);
    }
    return visibleItems;
  };

  const itemsPerPage = isMdUp ? 3 : isSmUp ? 2 : 1;

  return (
    <Box component="section" sx={{ py: 8 }} id="project">
      <Container>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h3" sx={{  color: 'primary.main', marginBottom: 4 }}>
            <Divider>{layoutDetails?.title}</Divider>
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ maxWidth: 400, mx: "auto", mb: 4 }}
          >
            {layoutDetails?.description}
          </Typography>
        </Box>

        <AutoPlaySwipeableViews
          interval={3000}
          enableMouseEvents
          resistance
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        >
          {Array.from(
            {
              length: Math.ceil(landingDetails?.length - 1 / itemsPerPage),
            },
            (_, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  px: 1,
                }}
              >
                <Grid container spacing={3} margin={1} sx={{ justifyContent: "center" }}>
                  {getVisibleItems(idx * itemsPerPage, itemsPerPage).map(
                    (item, index) => (
                      <Grid
                        key={index}
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Card
                          elevation={3}
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            borderRadius: 2,
                            transition: "all 250ms ease",
                            position: "relative",
                            minHeight: "350px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            "&:hover": {
                              borderColor: theme.palette.primary.main,
                            },
                            "&:hover .cardHeader": {
                              backgroundColor: theme.palette.primary.main,
                              color: "white",
                            },
                            "&:hover .largeText": {
                              color: "rgba(var(--primary),0.3)",
                            },
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Box
                              className="cardHeader"
                              sx={{
                                padding: "1.25rem 2rem",
                                fontSize: "1rem",
                                fontWeight: 500,
                                backgroundColor: "rgba(var(--primary),0.3)",
                                transition: "all 250ms ease",
                              }}
                            >
                              {item.title}
                            </Box>
                            <Box sx={{ padding: "1.5rem 2rem" }}>
                              <Typography
                                variant="body2"
                                sx={{ mb: 2, mt: 0 }}
                              >
                                {item.description}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ padding: "1.5rem 2rem" }}>
                            <Button variant="text" color="primary">
                              CLICK TO VIEW MORE
                            </Button>
                          </Box>
                          <Typography
                            className="largeText"
                            sx={{
                              textAlign: "right",
                              fontSize: "8rem",
                              fontWeight: 400,
                              lineHeight: 1,
                              color: "rgba(var(--primary),0.1)",
                              transition: "all 250ms ease",
                              position: "absolute",
                              bottom: "1rem",
                              right: "1rem",
                            }}
                          >
                            {idx * itemsPerPage + index + 1}
                          </Typography>
                        </Card>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            )
          )}
        </AutoPlaySwipeableViews>
      </Container>
    </Box>
  );
};

export default Projects1;
