// ErrorPage.js

import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}>
      <Box>
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Page Error
        </Typography>
        <Typography variant="body1" gutterBottom>
          An unexpected error has occurred.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
