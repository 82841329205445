import React, { useEffect, useState } from 'react';
import courseLists from './model';

const CourseListsComponent = (props) => {
  const { websiteDetails } = props;
  const [courseListNumber, setCourseListNumber] = useState(websiteDetails.courseListNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setCourseListNumber(websiteDetails.courseListNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const CourseListComponent = courseLists[courseListNumber];
  return (
    <div>
      {CourseListComponent ? <CourseListComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setCourseListNumber(courseListNumber - 1 > 0 ? courseListNumber - 1 : 1)}> - </button>
          <span>Version {courseListNumber}</span>
          <button onClick={() => setCourseListNumber(courseLists[courseListNumber + 1] ? courseListNumber + 1 : courseListNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default CourseListsComponent;
