import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  Divider,
  Icon,
  IconButton,
  Typography,
  Box,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Blog1 = (props) => {
  const { landingDetails, layoutDetails } = props;
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    let intervalId;
    if (!isHovered && landingDetails?.length > 3) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % landingDetails?.length);
      }, 3000); // Adjust interval as needed
    }
    return () => clearInterval(intervalId);
  }, [isHovered]);

  const itemsPerPage = isMdUp ? 3 : isSmUp ? 2 : 1;

  // Get the next items based on currentIndex and itemsPerPage
  const getVisibleCards = (index) => {
    const visibleCards = [];
    for (let i = 0; i < itemsPerPage; i++) {
      visibleCards.push(landingDetails[(index + i) % landingDetails?.length]);
    }
    return visibleCards;
  };


  return (
    <Box component="section" sx={{ py: 8 }} id="blog">
      <Container>
        <Box textAlign="center" mb={8}>
          <Typography variant="h3" sx={{  color: 'primary.main', marginBottom: 4 }}>
            <Divider>{layoutDetails?.title}</Divider>
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: 600, mx: "auto" }}>
            {layoutDetails?.description}
          </Typography>
        </Box>

        <AutoPlaySwipeableViews
          interval={3000}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          index={currentIndex}
          onChangeIndex={(index) => setCurrentIndex(index)}
          enableMouseEvents
          resistance
        >
          {Array.from({ length: Math.ceil(landingDetails?.length - 1 / itemsPerPage) }).map((_, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                justifyContent: "center",
                px: 1,
              }}
            >
              <Grid container spacing={3}>
                {getVisibleCards(idx * itemsPerPage).map((card, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card
                      elevation={3}
                      sx={{
                        height: "100%",
                        borderTop: `2px solid ${theme.palette.divider}`,
                        borderBottom: `2px solid ${theme.palette.divider}`, // Ensure the bottom border line is visible
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Stronger shadow effect
                        transition: "border-top-color 0.3s, box-shadow 0.3s",
                        "&:hover": {
                          borderTopColor: theme.palette.primary.main,
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)", // Stronger shadow on hover
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          height: 200, // Fixed height for all images
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          component="img"
                          src={card.image}
                          alt={card.title}
                          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                        <Box
                          className="imageOverlay"
                          sx={{
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            zIndex: 1,
                            opacity: 0,
                            background: "rgba(0,0,0,0.67)",
                            transition: "all 250ms ease",
                          }}
                        >
                          <Button
                            variant="text"
                            color="primary"
                            sx={{ color: "white" }}
                          >
                            Click to read more
                          </Button>
                        </Box>
                      </Box>
                      <Box sx={{ px: 2, pt: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: 1,
                              padding: "0.3rem 0.6rem",
                              background: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "rgba(255,255,255,0.4)"
                                  : "rgba(var(--primary),0.4)",
                              textAlign: "center",
                              marginRight: 2,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ color: "white", m: 0 }}
                            >
                              {card.created_at}
                            </Typography>
                          </Box>
                          <Typography variant="h5" sx={{ m: 0 }}>
                            {card.title}
                          </Typography>
                        </Box>
                        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                          {card.content}
                        </Typography>
                        <Divider sx={{ backgroundColor: "light-primary" }} />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: 1,
                          }}
                        >
                          <IconButton>
                            <Icon fontSize="small" color="primary">
                              share
                            </Icon>
                          </IconButton>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Container>
    </Box>
  );
};

export default Blog1;
