import Services1 from "./Services1";
import Services2 from "./Services2";
import Services3 from "./Services3";
import Services4 from "./Services4";
import Services5 from "./Services5";
import Services6 from "./Services6";
import Services7 from "./Services7";
import Services8 from "./Services8";
import Services9 from "./Services9";
import Services10 from "./Services10";
import Services11 from "./Services11";
import Services12 from "./Services12";
import Services13 from "./Services13";

const services = {
    1: Services1,
    2: Services2,
    3: Services3,
    4: Services4,
    5: Services5,
    6: Services6,
    7: Services7,
    8: Services8,
    9: Services9,
    10: Services10,
    11: Services11,
    12: Services12,
    13: Services13
};

export default services;