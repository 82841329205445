import Banner1 from "./Banner1";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";
import Banner4 from "./Banner4";
import Banner5 from "./Banner5";
import Banner6 from "./Banner6";
import Banner7 from "./Banner7";
import Banner8 from "./Banner8";
import Banner9 from "./Banner9";
import Banner10 from "./Banner10";
import Banner11 from "./Banner11";
import Banner12 from "./Banner12";

const banners = {
    1: Banner1,
    2: Banner2,
    3: Banner3,
    4: Banner4,
    5: Banner5,
    6: Banner6,
    7: Banner7,
    8: Banner8,
    9: Banner9,
    10: Banner10,
    11: Banner11,
    12: Banner12
};

export default banners;
