
import React from 'react';
import {
    Flex,
    SimpleGrid,
    Text,
    Icon,
    Image,
    useColorModeValue,
} from '@chakra-ui/react';


import Statistics from 'dashboard/views/admin/main/account/application/components/MiniStatistics';
import IconBox from 'dashboard/components/icons/IconBox';
// Assets
import { MdOutlineBarChart, MdPerson, MdFileCopy } from 'react-icons/md';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import FakeBarChart from 'dashboard/assets/img/account/FakeBarChart.png';

export default function AnalysisHeader(porps) {
    const {data} = porps;
    // Chakra Color Mode
    const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
    const iconColor = useColorModeValue('brand.500', 'white');
    return (
        <>
        { data && data.length > 0 && 
        <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 2, xl: 2, '2xl': 4 }}
            gap="20px"
            mb="20px"
        >
            {data.map((item, index) => (
                 <Flex>
                 <Statistics
                     focused={true}
                     bgGradient="linear(to-b, brand.400, brand.600)"
                     title={item.title}
                     value={item.value}
                     detail={
                         <Flex align="center">
                             <Icon as={RiArrowUpSFill} color="white" />
                             <Text color="white" fontSize="sm" mx="3px" fontWeight="500">
                                 +20%
                             </Text>
                             <Text color="white" fontSize="sm" fontWeight="500">
                                 Since last month
                             </Text>
                         </Flex>
                     }
                     illustration={
                         <IconBox
                             w="80px"
                             h="80px"
                             bgGradient="linear(to-b, brand.600, brand.400)"
                             icon={
                                 <Icon
                                     as={item.icon ? item.icon : MdOutlineBarChart}
                                     w="38px"
                                     h="38px"
                                     color="white"
                                 />
                             }
                         />
                     }
                 />
             </Flex>
            ))}
            <Flex>
                <Statistics
                    focused={true}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    title={'Total Income'}
                    value="$4.347"
                    detail={
                        <Flex align="center">
                            <Icon as={RiArrowUpSFill} color="white" />
                            <Text color="white" fontSize="sm" mx="3px" fontWeight="500">
                                +20%
                            </Text>
                            <Text color="white" fontSize="sm" fontWeight="500">
                                Since last month
                            </Text>
                        </Flex>
                    }
                    illustration={
                        <IconBox
                            w="80px"
                            h="80px"
                            bgGradient="linear(to-b, brand.600, brand.400)"
                            icon={
                                <Icon
                                    as={MdOutlineBarChart}
                                    w="38px"
                                    h="38px"
                                    color="white"
                                />
                            }
                        />
                    }
                />
            </Flex>
            <Flex>
                <Statistics
                    title={'Spendings'}
                    value="$1.249"
                    detail={
                        <Flex align="center">
                            <Icon as={RiArrowDownSFill} color="red.500" />
                            <Text color="red.500" fontSize="sm" mx="4px" fontWeight="700">
                                -12%
                            </Text>
                            <Text
                                color="secondaryGray.600"
                                fontSize="sm"
                                fontWeight="500"
                            >
                                Since last month
                            </Text>
                        </Flex>
                    }
                    illustration={<Image src={FakeBarChart} />}
                />
            </Flex>
            <Flex>
                <Statistics
                    title={'Activity'}
                    value="1.920"
                    detail={
                        <Flex align="center">
                            <Icon as={RiArrowUpSFill} color="green.500" />
                            <Text
                                color="green.500"
                                fontSize="sm"
                                mx="4px"
                                fontWeight="700"
                            >
                                +16%
                            </Text>
                            <Text
                                color="secondaryGray.600"
                                fontSize="sm"
                                fontWeight="500"
                            >
                                Since last month
                            </Text>
                        </Flex>
                    }
                    illustration={
                        <IconBox
                            w="80px"
                            h="80px"
                            bg={iconBg}
                            icon={
                                <Icon color={iconColor} as={MdPerson} w="38px" h="38px" />
                            }
                        />
                    }
                />
            </Flex>
            <Flex>
                <Statistics
                    title={'Total Projects'}
                    value="670"
                    detail={
                        <Flex align="center">
                            <Icon as={RiArrowUpSFill} color="green.500" />
                            <Text
                                color="green.500"
                                fontSize="sm"
                                mx="4px"
                                fontWeight="700"
                            >
                                +27%
                            </Text>
                            <Text
                                color="secondaryGray.600"
                                fontSize="sm"
                                fontWeight="500"
                            >
                                Since last month
                            </Text>
                        </Flex>
                    }
                    illustration={
                        <IconBox
                            w="80px"
                            h="80px"
                            bg={iconBg}
                            icon={
                                <Icon color={iconColor} as={MdFileCopy} w="28px" h="28px" />
                            }
                        />
                    }
                />
            </Flex>
        </SimpleGrid>
        }
        </>
    );
}
