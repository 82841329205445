import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  Grid,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router';
import blogData from './BlogData';
import NotFound from '../../pages/NotFound';
import { API } from 'utilities/api';

export default function BlogPage(props) {
  const { websiteDetails } = props;
  const theme = useTheme();
  const { slug } = useParams();
  const [blogDetails, setBlogDetails] = React.useState(null);
  
  // useEffect(() => {
  //   if (slug) {
  //     API.getStageDetailsById({title: slug, brand_id, stage: 'blogs'})
  //     .then((response) => {
  //         toast.success(response?.message || response);
  //         // Handle any redirection or further actions after successful submission
  //         fetchBrands();
  //         setShowCreateModal(false);
  //         // window?.location?.reload();
  //     })
  //     .catch((error) => {
  //         toast.error(error?.message || error);
  //     });
  //     const blog = blogData.find(blog => blog.slug === slug);
  //     if (blog) setBlogDetails(blog);
  //   }
  // },[])

  return (
    <> { blogDetails ? (
      <>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%', // Ensures the box takes full width of its container
          // maxWidth: '500px', // Example: set a max-width
          maxHeight: '600px', // Example: set a max-height
          background: `url(${blogDetails.image})`,
          // backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          // margin: 'auto', // Center the box horizontally
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 'auto',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            overflow: 'hidden',
            height: '70px',
            transform: 'translateZ(0)',
          }}
        >
        </Box>
      </Box>
      <Box sx={{ py: 16, bgcolor: theme.palette.background.default }}>
        <Container>
          <Paper
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              breakWords: 'break-word',
              width: '100%',
              mb: 6,
              boxShadow: 3,
              borderRadius: 5,
              mt: -64,
              backdropFilter: "blur(8px)",
              bgcolor: "transparent",
            }}
          >
            <Box sx={{ margin: 4 }}>
              <Box sx={{ textAlign: 'center', margin: 4 }}>
                <Typography variant="h3" sx={{ fontWeight: 'bold'}}>
                  {blogDetails.title}
                </Typography>
              </Box>
              <Box sx={{borderTop: `3px solid ${theme.palette.divider}`}}>
                <Grid container>
                  <Grid>
                    <Typography variant="body1" color="textPrimary" sx={{margin: 4}}>
                    <span dangerouslySetInnerHTML={{ __html: blogDetails.paragraph }} />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ px: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
              <Box sx={{ textAlign: 'left', mt: 2 }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2 }}
                >
                 {blogDetails.author.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textTransform: 'uppercase', mb: 2 }}
                >
                  {blogDetails.author.designation}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textTransform: 'uppercase', mb: 2 }}
                >
                  {blogDetails.publishDate}
                </Typography>
              </Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box> </> ) : ( <NotFound />) }
    </>
  );
}
