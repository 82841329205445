import React, { useState } from "react";

import "react-perfect-scrollbar/dist/css/styles.css";
import Main from "./pages";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
// import './dashboard/assets/css/App.css';
import initialTheme from './dashboard/theme/theme'; //  { themeGreen }

function App() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme); 

  return (
    
      <>
        <ToastContainer/>
          <Main theme={currentTheme} setTheme={setCurrentTheme}  />
      </>
  );
}

export default App;
