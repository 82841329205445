import { fetchAPIFrom , headerJson, headerFormData} from './common';

export const API = {

  signUp: async (data, referral) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `signup/${referral}`, data, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
      }, 'post').then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  signIn: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `signin`, data, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  verificationMail: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `auth/confirm`, data, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  resendVerificationMail: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `auth/sendVerifyMail`, data, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getBrandById: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/profile/findBrandById`, data,{
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },
  getBrandsFromAccessListbyUserId: async () => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/access/getBrandsFromAccessListbyUserId`, {
        cache: 'no-cache',
        headers: headerJson()
      }, null, 'get').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  submitBrandData: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/profile/createBrand`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  updateBrandData: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/profile/update`, data, {
        cache: 'no-cache',
        headers: headerFormData()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  // stages api
  insertUpdateItemToStage: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/saveAndUpdate`, data, {
        cache: 'no-cache',
        headers: headerFormData()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  deleteItemFromStage: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/deleteById`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getStageById: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/findById`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getStageByBrandId: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/getStageByBrandId`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getAllStageByBrandId: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/getAllByBrandId`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getAllByDomain: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/getAllByDomain`, data, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getStageFieldsBytype: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `brand/stages/getStageFieldsBytype`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  sendContracUs: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `contactus`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  subscribe: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `subscribe`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },

  getStageDetailsById: async (data) => {
    return new Promise((resolve, reject) => {
      fetchAPIFrom(
        `getStageDetailsById`, data, {
        cache: 'no-cache',
        headers: headerJson()
      }, 'post').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error);
      })
    });
  },
};
