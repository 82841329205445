import React, { Suspense, useEffect, useState } from "react";
import { scrollTo } from "../utils";
import {
  AboutsComponent,
  BannersComponent,
  BlogsComponent,
  BookingsComponent,
  CallToActionsComponent,
  ClientsComponent,
  ConferencesComponent,
  ContactUssComponent,
  CourseListsComponent,
  EventsComponent,
  FaqsComponent,
  FootersComponent,
  GallerysComponent,
  MarketingsComponent,
  PortfoliosComponent,
  PricingsComponent,
  ProductAcvtivitysComponent,
  SpeakersComponent,
  StoriesComponent,
  SubscriptionsComponent,
  RecommendationsComponent,
  ProjectsComponent,
  HeadersComponent,
  ServicesComponent
} from "../components";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";

const componentsMap = {
  headers: HeadersComponent,
  banners: BannersComponent,
  services: ServicesComponent,
  portfolios: PortfoliosComponent,
  recommendations: RecommendationsComponent,
  callToActions: CallToActionsComponent,
  pricings: PricingsComponent,
  footers: FootersComponent,
  contactUss: ContactUssComponent,
  blogs: BlogsComponent,
  clients: ClientsComponent,
  productAcvtivitys: ProductAcvtivitysComponent,
  conferences: ConferencesComponent,
  events: EventsComponent,
  gallerys: GallerysComponent,
  speakers: SpeakersComponent,
  stories: StoriesComponent,
  subscriptions: SubscriptionsComponent,
  projects: ProjectsComponent,
  bookings: BookingsComponent,
  faqs: FaqsComponent,
  courseLists: CourseListsComponent,
  marketings: MarketingsComponent,
  abouts: AboutsComponent
};

const Main = (props) => {
  const { darkMode, handleThemeChange , theme, setTheme } = props;
  useEffect(() => {
    scrollTo("root");
  }, [scrollTo]);

  const [websiteDetails, setWebsiteDetails] = useState({
    headersNumber: 1,
    bannersNumber: 12,
    servicesNumber: 1,
    portfolioNumber: 1,
    recommendationsNumber: 3,
    callToActionNumber: 1,
    pricingNumber: 1,
    marketingNumber: 3,
    footerNumber: 2,
    contactUsNumber: 4,
    isOwner: false
  });
  const [componentsOrder, setComponentsOrder] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      setComponentsOrder([
        'banners',
        'stories',
        'services',
        'clients',
        'recommendations',
        'blogs',
        'projects',
        'contactUss',
      ]);
      setWebsiteDetails({
        headersNumber: 13,
        bannersNumber: 12,
        servicesNumber: 1,
        portfoliosNumber: 1,
        recommendationsNumber: 3,
        callToActionsNumber: 1,
        marketingNumber: 3,
        pricingsNumber: 1,
        contactUsNumber: 4,
        footerNumber: 2,
        isOwner: false
      });
    };
    fetchData();
  }, []);

  const router = routes({ darkMode, handleThemeChange, websiteDetails, componentsOrder, componentsMap, theme, setTheme });

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};

export default Main;
