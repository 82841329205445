import React, { useEffect, useState } from 'react';
import pricings from './model';

const PricingsComponent = (props) => {
  const { websiteDetails } = props;
  const [pricingNumber, setPricingNumber] = useState(websiteDetails.pricingNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setPricingNumber(websiteDetails.pricingNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const PricingComponent = pricings[pricingNumber];
  return (
    <div>
      {PricingComponent ? <PricingComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setPricingNumber(pricingNumber - 1 > 0 ? pricingNumber - 1 : 1)}> - </button>
          <span>Version {pricingNumber}</span>
          <button onClick={() => setPricingNumber(pricings[pricingNumber + 1] ? pricingNumber + 1 : pricingNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default PricingsComponent;
