// Chakra imports
import { Flex, Text, Avatar, useColorModeValue } from "@chakra-ui/react";
import Card from "dashboard/components/card/Card.js";
import profileAvatar from "dashboard/assets/img/avatars/avatar4.png";
import React from "react";

export default function Profile(props) {
  const { name, domain, email, logo, profilePcture, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  // Chakra Color Mode
  return (
    <Card mb='20px' {...rest}>
      <Flex align='center'>
        {profilePcture ? <Avatar src={profilePcture} h='87px' w='87px' me='20px' /> : <Avatar src={logo} h='87px' w='87px' me='20px' /> }
        <Flex direction='column'>
          <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
            {name}
          </Text>
          <Text mt='1px' color={textColorSecondary} fontSize='md'>
            {domain ? domain : email}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
