import React, { useEffect } from "react";
import { Box, Container, Divider, Typography } from "@mui/material";

// Array of client objects with image URLs
const clients = [
  { id: 1, src: "https://www.hubwatch.io/wp-content/uploads/2022/10/Logolight.png", alt: "Hubwatch", url: "https://hubwatch.io/" },
  { id: 2, src: "https://unova.io/wp-content/uploads/2021/11/Unova-logo-new.png", alt: "Unova", url: "https://unova.io/" },
  { id: 3, src: "https://www.mozayc.net/img/logo-transparent.png", alt: "Mozayc", url: "https://mozayc.net/" },
  { id: 4, src: "https://www.kernadler.com/images/logo.png", alt: "KernAdler", url: "https://kernadler.com" },
  { id: 5, src: "https://tripa.ai/wp-content/uploads/2022/07/tripa-dark-logo.svg", alt: "Tripa", url: "https://tripa.ai" },
  { id: 6, src: "https://wavemint.io/assets/svg/wavemint.svg", alt: "WaveMint", url: "https://wavemint.io" },
  { id: 7, src: "https://fidgy.io/wp-content/uploads/2024/03/FidgyLogo.png", alt: "Fidgy", url: "https://fidgy.io" },
  { id: 8, src: "https://www.ing.com/static/ingdotcompresentation/static/img/logos/logo.hd.png", alt: "ING", url: "https://www.ing.com" },
];


const Clients1 = (porps) => {
  const { landingDetails, layoutDetails } = porps;
  return (
    
    <Box
      id="client"
    >
      <Box component="section" sx={{ py: 8}} id="service">
      <Container>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h3" sx={{  color: 'primary.main', marginBottom: 4 }}>
          <Divider>{layoutDetails?.title}</Divider>
        </Typography>
        <Typography variant="body1" sx={{ margin: 'auto', marginBottom: 8 }}>
          {layoutDetails?.description}
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
          {landingDetails?.map((client, index) => (
            <Box maxHeight={100} maxWidth={100} key={index} component="img" src={client.image} alt={client.name} sx={{  margin: 2 }}  onClick={() => window.open(client.description, '_blank')}/>
          ))}
        </Box>
      </Box>
      </Container>
    </Box>
     
    </Box>
  );
};

export default Clients1;
