import React, { useEffect, useState } from 'react';
import { API } from 'utilities/api';
import { Button, Flex, FormControl, useColorModeValue, SimpleGrid, Stack, Text, Box, Icon } from "@chakra-ui/react";
import DialogModal from "dashboard/layouts/auth/types/popup";
import InputField from 'dashboard/components/fields/InputField';
import SwitchField from "dashboard/components/fields/SwitchField";
import Dropzone from 'dashboard/components/upload';
import { MdOutlineCloudUpload } from 'react-icons/md';


const ActionPopup = (props) => {
  const { name, popupModal, openDialog, setOpenDialog, entryData , handleCreate, handleDelete, handleEdit} = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const textColorSecondary = "gray.400";
  // const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [formData, setFormData] = useState(entryData?.data ? entryData.data : {});
  const [mode, setMode] = useState(entryData?.mode ? entryData.mode : 'add');
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    setFormData(entryData?.data?._id ? entryData.data : {});
    setMode(entryData?.mode ? entryData.mode : 'add');
  }, [entryData])
  // Handle input change
  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value ? e.target.value : e.target.checked });
  };
  


  const handleImageUpload = (file) => {
    // Handle logo upload and set the logo file state
    setImageFile(file);
};

const handleCreateModal = () => {
    handleCreate(formData, imageFile).then(() => {
        setOpenDialog(false);
    })
};

const handleEditModal = () => {
    handleEdit(formData, imageFile).then(() => {
        setOpenDialog(false);
    })
};

const handleDeleteModal = () => {
    handleDelete(formData).then(() => {
        setOpenDialog(false);
    })
};


  return (
    <>
      <DialogModal
        title={mode === 'add' ? `Add ${name}` : mode === 'edit' ? `Edit ${name}` : 'Delete'}
        isOpenAction={openDialog}
        textColor={textColor}
        onCloseAction={() => setOpenDialog(false)}
        cardTop={{ base: "140px", md: "24vh" }}
        cardBottom={{ base: "50px", lg: "auto" }}
        key={name}>
        <Flex
          w='100%'
          maxW='max-content'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          justifyContent='center'
          px={{ base: "25px", md: "0px" }}
          flexDirection='column'>
          {/* <Box me='auto' mb='34px'>
          <Text
            color={textColorSecondary}
            fontSize='md'
            maxW={{ base: "95%", md: "100%" }}
            mx={{ base: "auto", lg: "unset" }}
            textAlign={{ base: "center", lg: "left" }}>
            Add a {name}
          </Text>
        </Box> */}
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "425px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl>
              <Flex direction="column" w="100%" key={name}>
                <SimpleGrid columns={{ base: 1, md: 1 }} gap="10px" >
                  <Stack direction="column" gap="10px" >
                    {mode === 'delete' ? (
                      <Text
                        color={textColor}
                        key={"delete-popup"}
                        fontSize='xl'
                        fontWeight='600'
                        lineHeight='100%'>
                        Are you sure you want to delete this {formData.title ? formData.title : name}?
                      </Text>
                    ) : (
                      <>
                        {popupModal.map((field, index) => (
                          <>
                            {field === 'published' ? (
                              <SwitchField
                                id={name + index}
                                label={field}
                                onChange={(e) => handleInputChange(e, field)}
                                isChecked={formData[field] || false}

                              // desc='Get personalized offers and emails based on your activity.'
                              />
                            ) : field === 'image' ? (
                              <Dropzone
                                onFileUploaded={handleImageUpload} // Callback to handle logo upload
                                file={entryData?.data?.image}
                                content={
                                  <Box>
                                    <Icon
                                      as={MdOutlineCloudUpload}
                                      w="80px"
                                      h="80px"
                                      color={textColor}
                                    />
                                    <Text
                                      mx="auto"
                                      mb="12px"
                                      fontSize="lg"
                                      fontWeight="700"
                                      whiteSpace="pre-wrap"
                                      color={textColor}
                                    >
                                      Drop your logo here, or{' '}
                                      <Text
                                        as="span"
                                        fontSize="lg"
                                        fontWeight="700"
                                        color={textColorBrand}
                                      >
                                        browse
                                      </Text>
                                    </Text>
                                    <Text
                                      fontSize="sm"
                                      fontWeight="500"
                                      color="secondaryGray.500"
                                    >
                                      PNG, JPG and GIF files are allowed
                                    </Text>
                                  </Box>
                                }
                              />
                            ) : (
                              <InputField
                                mb="0px"
                                id={name + index}
                                name={field}
                                value={formData[field] || ''}
                                onChange={(e) => handleInputChange(e, field)}
                                placeholder={`Enter ${field}`}
                                label={field}
                              />
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </Stack>
                </SimpleGrid>
              </Flex>

              <Button
                fontSize='14px'
                variant='brand'
                borderRadius='16px'
                fontWeight='500'
                w='100%'
                h='50'
                // mb='24px'
                mt='12px'
                onClick={mode === 'delete' ? handleDeleteModal : mode === 'edit' ? handleEditModal : handleCreateModal}
              >
                {mode === 'delete' ? 'Delete' : 'Submit'}
              </Button>
            </FormControl>

          </Flex>
        </Flex>
      </DialogModal>
    </>
  );
};

export default ActionPopup;
