
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import Delete from "dashboard/pages/brand/settings/components/Delete";
import Information from "dashboard/pages/brand/settings/components/Information";
import Profile from "dashboard/pages/brand/settings/components/Profile";
import Socials from "dashboard/pages/brand/settings/components/Socials";
import React, { useEffect } from "react";
import { useOutletContext } from "react-router";
import { toast } from "react-toastify";
import { API } from "utilities/api";

export default function BrandSetting() {
  const [selectedBrand] = useOutletContext();
  const [brandData, setBrandData] = React.useState({});

  function fetchData() {
    return API.getBrandById({ brand_id: selectedBrand}).then((res) => {
        setBrandData(res)
    }).catch((err) => {
      toast.error(err)
      console.log('error fetchData',err)
    })
  }

  useEffect(() => {
    fetchData();
  }, [selectedBrand])
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {/* Column Left */}
        <Flex direction='column'>
          <Profile name={brandData?.name} logo={brandData?.logo_upload_path} domain={brandData?.domain} />
          <Information initData={brandData} fetchData={fetchData}/>
        </Flex>
        {/* Column Right */}
        <Flex direction='column'>
          <Socials brandData={brandData} />
          <Delete  brandData={brandData}/>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
