import { Flex } from "@chakra-ui/react";
import React , { useEffect } from "react";
import TableManagement from "dashboard/components/tableList";
// import SearchTableUsers from "dashboard/pages/brand/IAM/overview/components/SearchTableUsersOverivew";
import { columnsDataUsersOverview } from "dashboard/pages/brand/IAM/variables/columnsDataUsersOverview";
import tableDataUsersOverview from "dashboard/pages/brand/IAM/variables/tableDataUsersOverview.json";
import { toast } from "react-toastify";
import { API } from "utilities/api";
import { useOutletContext } from "react-router";
import AnalysisHeader from "dashboard/components/analysisHeader";

export default function IAMComponent(props) {
  const [selectedBrand] = useOutletContext();
  const { stage } = props

  const [tableData, setTableData] = React.useState([]);
  const [columnsData, setColumnsData] = React.useState([]);
  const [popupModal, setPopupModal] = React.useState();
  function getPageData() {
    return API.getAccessList({ brand_id: selectedBrand, stage}).then((res) => {
      if (res.entries && res.entries.length === 0) {
        setTableData(tableDataUsersOverview)
        setColumnsData(columnsDataUsersOverview)
        setPopupModal(res.stage)
      } else {
        setTableData(res.entries)
        setPopupModal(res.stage)
        const keys = Object.keys(res.entries[0]).filter(key => !['_id', '__v', 'brand_id', 'draft'].includes(key));
        const headersArray = keys.map(key => ({ Header: key, accessor: key}));
        headersArray.push({ Header: 'ACTIONS', accessor: 'actions' })
        setColumnsData(headersArray)
      }
    }).catch((err) => {
      console.log('getPageData',err)
    })
  }
  const handleAddUser = (formData) => {
    return API.insertUser(formData).then((res) => {
      toast.success(res.message)
    }).catch((err) => {
      toast.error(err)
    })
  }

  const removeUserFromAccessList = (formData) => {
    return API.removeUserFromAccessList({ _id : formData._id }).then((res) => {
      toast.success(res.message)
    }).catch((err) => {
      toast.error(err)
    })
  }

  const updateUserRole = (formData) => {
    return API.updateUserRole({ _id: formData._id, role: formData.role }).then((res) => {
      toast.success(res.message)
    }).catch((err) => {
      toast.error(err)
    })
  }
  

  useEffect(() => {
    // getPageData();
    setTableData(tableDataUsersOverview)
        setColumnsData(columnsDataUsersOverview)
  }, [stage])

  return (
    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        <AnalysisHeader />
        <TableManagement
          getPageData={getPageData}
          tableData={tableData}
          columnsData={columnsData}
          name={'User Management'}
          popupModal={popupModal}
          selectedBrand={selectedBrand}
          handleCreate={handleAddUser}
          handleDelete={removeUserFromAccessList}
          handleEdit={updateUserRole}
        />
          </Flex>
          </Flex>
  );
}
