// Chakra imports
import { Button, Flex, Image, Input, IconButton, useColorModeValue } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { CloseIcon } from "@chakra-ui/icons";
// Assets
import React, { useState } from "react";

function Dropzone(props) {
  const { content, onFileUploaded, file, ...rest } = props;
  const [preview, setPreview] = useState(file ? file : null);
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: acceptedFiles => {
      const previewUrl = URL.createObjectURL(acceptedFiles[0]);
      setPreview(previewUrl);
      onFileUploaded(acceptedFiles[0]);
    },
    noClick: true, // Prevents opening file dialog on click
    noKeyboard: true // Prevents opening file dialog on key press
  });
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("gray.300", "whiteAlpha.100");

  const handleRemoveImage = (e) => {
    e.stopPropagation(); // Prevents the dropzone click event
    setPreview(null);
    onFileUploaded(null);
  };

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w='100%'
      maxW='100%'
      h={{ base: "208px", "3xl": "300px" }}
      cursor="pointer"
      position="relative"
      overflow="hidden"
      {...getRootProps({ className: "dropzone" })}
      {...rest}
    >
      <Input variant="main" {...getInputProps()} />
      {preview ? (
        <>
          <Image
            src={preview}
            alt="Preview"
            objectFit="cover"
            w="100%"
            h="100%"
          />
          <IconButton
            icon={<CloseIcon />}
            position="absolute"
            top="10px"
            right="10px"
            onClick={handleRemoveImage}
            borderRadius="full"
            size="sm"
            zIndex="1"
          />
        </>
      ) : (
        <Button variant="no-effects" onClick={open}>{content}</Button>
      )}
    </Flex>
  );
}

export default Dropzone;
