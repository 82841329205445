import React, { useEffect, useState } from 'react';
import footers from './model';

const FootersComponent = (props) => {
  const { websiteDetails, layoutData } = props;
  const [footerNumber, setFooterNumber] = useState(websiteDetails.footerNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  const data = layoutData?.filter(items => items.stage_name.includes('aboutus'))[0];
  useEffect(() => {
    setFooterNumber(websiteDetails.footerNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const FooterComponent = footers[footerNumber];
  return (
    <div>
      {FooterComponent ? <FooterComponent {...props}  data={data} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setFooterNumber(footerNumber - 1 > 0 ? footerNumber - 1 : 1)}> - </button>
          <span>Version {footerNumber}</span>
          <button onClick={() => setFooterNumber(footers[footerNumber + 1] ? footerNumber + 1 : footerNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default FootersComponent;
