import React from "react";
import { Grid, Button, IconButton, LinearProgress, Typography, Box, Container } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

const RoundedLinearProgressbar = withStyles(({ palette }) => ({
  root: {
    borderRadius: 300,
    height: 6,
    background: "transparent",
  },
  bar: {
    borderRadius: 300,
  },
}))(LinearProgress);

const useStyles = makeStyles(({ palette }) => ({
  skillIcon: {
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: palette.primary.main,
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    height: 26,
    width: 26,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const About1 = () => {
  const classes = useStyles();
  const skillList = [
    {
      title: "Adobe Photoshop",
      icon: "Ps",
      skill: 98,
    },
    {
      title: "Adobe Illustrator",
      icon: "Ai",
      skill: 95,
    },
    {
      title: "Adobe Premiere Pro",
      icon: "Pr",
      skill: 90,
    },
    {
      title: "Adobe After Effects",
      icon: "Ae",
      skill: 85,
    },
    {
      title: "Adobe Indesign",
      icon: "Id",
      skill: 90,
    },
    {
      title: "Adobe XD",
      icon: "Xd",
      skill: 95,
    },
  ];

  return (
    <Box component="section" sx={{ py: 8 }} id="about">
      <Container>
        <Grid container spacing={6} alignItems="center">
          <Grid item sm={6} xs={12}>
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.light
                      : theme.palette.primary.dark,
                  marginBottom: 4,
                }}
              >
                About Me
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  color: "primary.main",
                  fontSize: "18px",
                  fontWeight: "medium",
                  marginBottom: 2,
                }}
              >
                I am a professional graphics designer
              </Typography>
              <Typography variant="body2" paragraph sx={{ mb: 4, maxWidth: 400 }}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
              </Typography>
              <Button variant="contained" color="primary" sx={{ borderRadius: 1, px: 7 }}>
                CONTACT ME
              </Button>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            {skillList.map((item, ind) => (
              <Box
                key={ind}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  mb: ind !== skillList.length - 1 ? 2 : 0,
                }}
              >
                <IconButton sx={{ backgroundColor: "primary.light", mr: 2, padding: "11px" }}>
                  <Box className={classes.skillIcon}>{item.icon}</Box>
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2" sx={{ mt: 0, mb: 1 }}>
                    {item.title}
                  </Typography>
                  <RoundedLinearProgressbar value={item.skill} variant="determinate" />
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About1;
