import { useColorModeValue, Menu, MenuButton, Button, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React , { useEffect } from 'react';
import CreateModal from 'dashboard/components/brand/create';

export function SidebarBrand(props) {
  const { selectedBrand, brandList, setSelectedBrand, fetchBrands } = props;
  const [selectedBrandName, setSelectedBrandName] = React.useState('');
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  useEffect(() => {
    if (brandList && brandList.length > 0) {
        const brandName = brandList.find(brand => brand.brand_id._id === selectedBrand)?.brand_id.name;
      setSelectedBrandName(brandName);
    }
  }, [brandList, selectedBrand]);
  const bgColor = useColorModeValue('gray.400', 'gray.200');

  return (
  <>
        <Menu>
    <MenuButton as={Button} bgColor={bgColor} rightIcon={<ChevronDownIcon />}>
        {selectedBrandName || 'Select a brand'}
    </MenuButton>
    <MenuList>
        <MenuItem value='create' onClick={() => setShowCreateModal(true)}>Create Brand</MenuItem>
        {brandList?.map((data) => (
            <MenuItem key={data.brand_id._id} value={data.brand_id._id} onClick={() => setSelectedBrand(data.brand_id._id)}>
                {data.brand_id.name}
            </MenuItem>
            ))}
    </MenuList>
    </Menu>
    {showCreateModal && <CreateModal  fetchBrands={fetchBrands} add={true} setShowCreateModal={setShowCreateModal}/>}
    </>
  );
}

export default SidebarBrand;
