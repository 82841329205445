import React, { useEffect, useState } from 'react';
import contactUss from './model';

const ContactUssComponent = (props) => {
  const { websiteDetails } = props;
  const [contactUsNumber, setContactUsNumber] = useState(websiteDetails.contactUsNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setContactUsNumber(websiteDetails.contactUsNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const ContactUsComponent = contactUss[contactUsNumber];
  return (
    <div>
      {ContactUsComponent ? <ContactUsComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setContactUsNumber(contactUsNumber - 1 > 0 ? contactUsNumber - 1 : 1)}> - </button>
          <span>Version {contactUsNumber}</span>
          <button onClick={() => setContactUsNumber(contactUss[contactUsNumber + 1] ? contactUsNumber + 1 : contactUsNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default ContactUssComponent;
