import React, { useEffect, useState } from 'react';
import speakers from './model';

const SpeakersComponent = (props) => {
  const { websiteDetails } = props;
  const [speakerNumber, setSpeakerNumber] = useState(websiteDetails.speakerNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setSpeakerNumber(websiteDetails.speakerNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const SpeakerComponent = speakers[speakerNumber];
  return (
    <div>
      {SpeakerComponent ? <SpeakerComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setSpeakerNumber(speakerNumber - 1 > 0 ? speakerNumber - 1 : 1)}> - </button>
          <span>Version {speakerNumber}</span>
          <button onClick={() => setSpeakerNumber(speakers[speakerNumber + 1] ? speakerNumber + 1 : speakerNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default SpeakersComponent;
