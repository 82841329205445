// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "dashboard/components/card/Card.js";
import InputField from "dashboard/components/fields/InputField";
import InformaitionDetails from "dashboard/pages/brand/settings/components/InformationDetails";
import TextField from "dashboard/components/fields/TextField";
import React from "react";

export default function Information(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  // Chakra Color Mode
  return (
    <FormControl>
      <Card mb='20px' {...rest}>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Brand Settings
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can change brand information
          </Text>
        </Flex>
        <InformaitionDetails {...rest}/>
      </Card>
    </FormControl>
  );
}
