import React from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";


    const Blogs = () => {
        const id = useParams();
        console.log('id', id);
        // const { websiteDetails, componentsOrder, componentsMap } = data;

    //   const renderComponent = (Component, number) => {
    //     if (!Component || number <= 0) return null;
    //     return <Component key={Math.random()} websiteDetails={websiteDetails} />;
    //   };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}>
        asdasd
        asdasdsad
        asdasdsadasd
        asd
        asd
        asd
        asdasdasdasdasd
        </Container>
    );
    };

    export default Blogs;
