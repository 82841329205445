import React, { useEffect, useState } from 'react';
import blogs from './model';

const BlogsComponent = (props) => {
  const { websiteDetails } = props;
  const [blogNumber, setblogNumber] = useState(websiteDetails.blogNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setblogNumber(websiteDetails.blogNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const BlogComponent = blogs[blogNumber];
  return (
    <div>
      {BlogComponent ? <BlogComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setblogNumber(blogNumber - 1 > 0 ? blogNumber - 1 : 1)}> - </button>
          <span>Version {blogNumber}</span>
          <button onClick={() => setblogNumber(blogs[blogNumber + 1] ? blogNumber + 1 : blogNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default BlogsComponent;
