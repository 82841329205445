import Recommendation1 from "./Recommendation1";
import Recommendation2 from "./Recommendation2";
import Recommendation3 from "./Recommendation3";
import Recommendation4 from "./Recommendation4";
import Recommendation5 from "./Recommendation5";
import Recommendation6 from "./Recommendation6";
import Recommendation7 from "./Recommendation7";
import Recommendation8 from "./Recommendation8";
import Recommendation9 from "./Recommendation9";
import Recommendation10 from "./Recommendation10";
import Recommendation11 from "./Recommendation11";

const Recommendations = {
    1: Recommendation1,
    2: Recommendation2,
    3: Recommendation3,
    4: Recommendation4,
    5: Recommendation5,
    6: Recommendation6,
    7: Recommendation7,
    8: Recommendation8,
    9: Recommendation9,
    10: Recommendation10,
    11: Recommendation11
};

export default Recommendations;