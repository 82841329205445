import React, { useState } from "react";
import {
  Grid,
  Card,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {API} from "../../utilities/api";

const Contact4 = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const formsData = new FormData();
      const data = {
        name: formData.firstName  + " " + formData.lastName,
        email: formData.email,
        title: formData.title,
        message: formData.message,
      }
      // formsData.append('name', formData.firstName  + " " + formData.lastName);
      // formsData.append('email', formData.email);
      // formsData.append('title', formData.title);
      // formsData.append('message', formData.message);

      // const data = new URLSearchParams(formsData);
      API.sendContracUs(data).then((response) => {
        toast.success('Email sent successfully');
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            title: '',
            message: '',
          })
      })
    } catch (error) {
      toast.error('Failed to send email');
      console.error(error);
    }
  };

  return (
    <Box component="section" sx={{ py: 8 }} id="contactus">
      <Container>
      <Typography variant="h3" sx={{  color: 'primary.main', marginBottom: 4 }}>
      <Divider>Contact us</Divider>
      </Typography>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={6} sm={5} xs={12}>
            <Box
              component="img"
              src="/assets/images/contact-1.png"
              alt="contact"
              className="w-full max-w-400 mx-auto block"
            />
          </Grid>
          <Grid item md={6} sm={7} xs={12}>
            <Card className="p-8 border-radius-8" elevation={3}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={formData.firstName}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={formData.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      placeholder="Email"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      label="title"
                      placeholder="Subject"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={formData.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="message"
                      label="Message"
                      placeholder="Message"
                      size="small"
                      variant="outlined"
                      multiline
                      rows={8}
                      fullWidth
                      onChange={handleChange}
                      value={formData.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreeTerms"
                          checked={formData.agreeTerms}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <p>
                          I accept the{" "}
                          <Link className="text-primary" to="/landing12">
                            Terms and Conditions
                          </Link>
                        </p>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact4;
