import React, { useMemo, useState } from "react";
import {
  Badge,
  Button,
  Flex,
  Icon,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";


import Card from "dashboard/components/card/Card.js";
import { SearchBar } from "dashboard/components/navbar/searchBar/SearchBar";
import StagePopup from "dashboard/components/tableList/actions";

// Assets
import { MdEdit, MdDelete, MdChevronRight, MdChevronLeft } from "react-icons/md";
import { RiAddCircleLine } from "react-icons/ri";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function TableManagement(props) {
  const { columnsData, tableData , name, popupModal, selectedBrand, getPageData} = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [openDialog, setOpenDialog] = useState(false);
  const [entryData, setEntryData] = useState({});

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    setPageSize,
    state,
  } = tableInstance;
  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;
  // const textColor = useColorModeValue("secondaryGray.900", "white");
  // const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const handleEdit = (data) => {
    setEntryData({data: data, mode: 'edit'});
    setOpenDialog(!openDialog);
  };

  const handleDelete = (data) => {
    setEntryData({data: data, mode: 'delete'});
    setOpenDialog(!openDialog);
  };

  return (
    <>
    <Card>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ lg: "center" }}
          justify={{ base: "space-between" }}
          w='100%'
          px='20px'
          mb='20px'>
          <Text
            color={textColor}
            fontSize='xl'
            fontWeight='600'
            lineHeight='100%'>
            {name?.charAt(0)?.toUpperCase() + name?.slice(1)}
          </Text>
          <SearchBar
            onChange={(e) => setGlobalFilter(e.target.value)}
            h='44px'
            w={{ lg: "390px" }}
            borderRadius='16px'
          />
          <Flex cursor='pointer' h='max-content' w='max-content'>
            <Icon
              // color='secondaryGray.500'
              color={brandColor}
              as={RiAddCircleLine}
              w='20px'
              h='20px'
              onClick={() => setOpenDialog(!openDialog)}
            />
          </Flex>
          {/* <Menu /> */}
          
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "ACTIONS") {
                      data = (
                        <>
                        <Flex align='center' cursor='pointer' h='max-content' w='max-content'>
                          <Icon
                            as={MdEdit}
                            color={brandColor}
                            w='20px'
                            h='20px'
                            me='16px'
                            onClick={() => handleEdit(cell.row.original)}
                          />
                          <Icon
                            as={MdDelete}
                            color={brandColor}
                            w='20px'
                            h='20px'
                            me='16px'
                            onClick={() => handleDelete(cell.row.original)}
                          />
                        </Flex>
                        </>
                      );
                    } else if (cell.column?.Header?.toLowerCase() === "published" || cell.column?.Header?.toLowerCase() === "STATUS ORDER") {
                      data = (
                        
                        <Badge
                          colorScheme={
                            !cell.value ? "red" : "green"
                          }
                          color={
                            !cell.value ? "red.500" : "green.500"
                          }
                          fontSize='sm'
                          fontWeight='600'>
                          {!cell?.value ? "Draft" : "Published"}
                        </Badge>
                        
                      );
                    } else if (cell.column?.Header?.toLowerCase() === "image") {
                      data = (
                        <Image
                          src={cell.value}
                          w='32px'
                          h='32px'
                          borderRadius='50%'
                          me='16px'
                        />
                      );
                    } else {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='600'>
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "120px", md: "200px", lg: "auto" }}
                        borderColor={borderColor}>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify='space-between'
          align={{ sm: "start", md: "center" }}
          w='100%'
          mt='20px'
          px={{ md: "22px" }}>
          <Flex align='center'>
            <Text
              me='10px'
              minW='max-content'
              fontSize='sm'
              color='gray.500'
              fontWeight='normal'>
              Show rows per page
            </Text>
            <Select
              fontSize='sm'
              variant='main'
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
            </Select>
          </Flex>
          <Stack
            direction='row'
            alignSelf={{ base: "unset", md: "flex-end" }}
            spacing='4px'
            ms={{ base: "0px", md: "auto" }}>
            <Button
              variant='no-effects'
              onClick={() => previousPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w='75px'
                mx='6px'
                defaultValue='1'
                onChange={(e) => gotoPage(e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant='no-effects'
                    transition='all .5s ease'
                    onClick={() => gotoPage(pageNumber - 1)}
                    w='40px'
                    h='40px'
                    borderRadius='50%'
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : "transparent"
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: "0.7",
                          }
                        : {
                            bg: "whiteAlpha.100",
                          }
                    }
                    key={index}>
                    <Text
                      fontSize='sm'
                      color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant='no-effects'
              onClick={() => nextPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </Card>
    {openDialog && popupModal?.length > 0 && <StagePopup entryData={entryData} getPageData={getPageData} selectedBrand={selectedBrand} popupModal={popupModal} name={name} openDialog={openDialog} setOpenDialog={setOpenDialog} key={name}/>}
    </>
  );
}

export default TableManagement;
