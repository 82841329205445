import React, { useEffect, useState } from 'react';
import Recommendations from './model';

const RecommendationsComponent = (props) => {
  const { websiteDetails } = props;
  const [recommendationNumber, setRecommendationNumber] = useState(websiteDetails.recommendationsNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setRecommendationNumber(websiteDetails.recommendationsNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const RecommendationComponent = Recommendations[recommendationNumber];
  return (
    <div>
      {RecommendationComponent ? <RecommendationComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setRecommendationNumber(recommendationNumber - 1 > 0 ? recommendationNumber - 1 : 1)}> - </button>
          <span>Version {recommendationNumber}</span>
          <button onClick={() => setRecommendationNumber(Recommendations[recommendationNumber + 1] ? recommendationNumber + 1 : recommendationNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default RecommendationsComponent;
