import React, { useEffect, useState } from 'react';
import clients from './model';

const ClientsComponent = (props) => {
  const { websiteDetails } = props;
  const [clientNumber, setClientNumber] = useState(websiteDetails.clientNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setClientNumber(websiteDetails.clientNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const ClientComponent = clients[clientNumber];
  return (
    <div>
      {ClientComponent ? <ClientComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setClientNumber(clientNumber - 1 > 0 ? clientNumber - 1 : 1)}> - </button>
          <span>Version {clientNumber}</span>
          <button onClick={() => setClientNumber(clients[clientNumber + 1] ? clientNumber + 1 : clientNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default ClientsComponent;
