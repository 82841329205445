import React, { useEffect, useState } from 'react';
import bookings from './model';

const BookingsComponent = (props) => {
  const { websiteDetails } = props;
  const [bookingNumber, setBookingNumber] = useState(websiteDetails.bookingNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setBookingNumber(websiteDetails.bookingNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const BookingComponent = bookings[bookingNumber];
  return (
    <div>
      {BookingComponent ? <BookingComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setBookingNumber(bookingNumber - 1 > 0 ? bookingNumber - 1 : 1)}> - </button>
          <span>Version {bookingNumber}</span>
          <button onClick={() => setBookingNumber(bookings[bookingNumber + 1] ? bookingNumber + 1 : bookingNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default BookingsComponent;
