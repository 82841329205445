import React, { useEffect, useState } from 'react';
import banners from './model';

const BannersComponent = (props) => {
  const { websiteDetails } = props;
  const [bannersNumber, setBannersNumber] = useState(websiteDetails.bannersNumber || 1);
  // const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setBannersNumber(websiteDetails.bannersNumber || 1);
    // setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const BannerComponent = banners[bannersNumber];
  return (
    <div>
      {BannerComponent ? <BannerComponent {...props} /> : <> </>}
      {/* {isOwner && (
        <div>
          <button onClick={() => setBannersNumber(bannersNumber - 1 > 0 ? bannersNumber - 1 : 1)}> - </button>
          <span>Version {bannersNumber}</span>
          <button onClick={() => setBannersNumber(banners[bannersNumber + 1] ? bannersNumber + 1 : bannersNumber)}> + </button>
        </div>
      )} */}
    </div>
  );
};

export default BannersComponent;
