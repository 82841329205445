import React, { useEffect, useState } from 'react';
import abouts from './model';

const AboutsComponent = (props) => {
  const { websiteDetails } = props;
  const [aboutNumber, setAboutNumber] = useState(websiteDetails.aboutNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setAboutNumber(websiteDetails.aboutNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const AboutComponent = abouts[aboutNumber];
  return (
    <div>
      {AboutComponent ? <AboutComponent {...props}/> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setAboutNumber(aboutNumber - 1 > 0 ? aboutNumber - 1 : 1)}> - </button>
          <span>Version {aboutNumber}</span>
          <button onClick={() => setAboutNumber(abouts[aboutNumber + 1] ? aboutNumber + 1 : aboutNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default AboutsComponent;
