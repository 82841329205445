import React, { useState } from "react";
import {
  Box,
  Container,
  Icon,
  Grid,
  Button,
  TextField,
  InputAdornment,
  Typography
} from "@mui/material";
import { darken } from "@mui/material/styles";
import FacebookIcon from "../common/icons/FacebookIcon";
import TwitterIcon from "../common/icons/TwitterIcon";
import InstagramIcon from "../common/icons/InstagramIcon";
import LinkedinIcon from "../common/icons/LinkedinIcon";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "utilities/api";

const companyOption = ["About Us", "Services", "Team", "Pricing", "Project"];
const usefulLink = [
  "Terms of Services",
  "Privacy Policy",
  "Documentation",
  "Changelog",
  "dashboard/components",
];

const Footer2 = (props) => {
  const { data } = props;
  const [subscribeMail, setSubscribeMail] = useState("");
  const [formData, setFormData] = useState({
    firstName: 'subscribe',
    lastName: 'subscribe',
    email: '',
    title: 'subscribe',
    message: 'subscribe',
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = 'https://binnonet.com/api/mail';

      const formsData = new FormData();
      formsData.append('name', formData.firstName  + " " + formData.lastName);
      formsData.append('email', formData.email);
      formsData.append('title', formData.title);
      formsData.append('message', formData.message);

      const data = new URLSearchParams(formsData);

      const response = await axios.post(apiUrl, data);

      if (response.status !== 200) {
        toast.error('Failed to Subscribe');
        throw new Error('Failed to Subscribe');
      }
      toast.success('Subscribe successfully');
      setFormData({
        firstName: 'subscribe',
        lastName: 'subscribe',
        email: '',
        title: 'subscribe',
        message: 'subscribe',
      })
    } catch (error) {
      toast.error('Failed to Subscribe');
      console.error(error);
    }
  };

  const handleSubscribeMail = () => {
    API.subscribe({ email: subscribeMail }).then((response) => {
      toast.success('Subscribe successfully');
      setSubscribeMail('');
    }).catch((error) => {
      toast.error(error);
    });
  };

  const handleMailChange = (e) => {
    setSubscribeMail(e.target.value);
  };
  return (
    <Box
      component="section"
      sx={{
        background: "#011C3A", // No need to change based on theme
        color: "#FFFFFF", // Set default text color to white
        py: 8,
      }}
      id="contact4"
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography variant="h4" sx={{ color: "#FFFFFF !important" }} fontSize={24} fontWeight="normal" mb={1}>
                {data?.title}
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFFFFF !important" }} my={2} maxWidth={200}>
                {data?.description}
              </Typography>
              <Box display="flex" flexWrap="wrap">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 1,
                    mr: 1.5,
                    border: `1px solid #FFFFFF`,
                    cursor: "pointer",
                  }}
                >
                  <FacebookIcon sx={{ color: "#FFFFFF !important" }} fontSize="small" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 1,
                    mr: 1.5,
                    border: `1px solid #FFFFFF`,
                    cursor: "pointer",
                  }}
                >
                  <TwitterIcon sx={{ color: "#FFFFFF !important" }} fontSize="small" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 1,
                    mr: 1.5,
                    border: `1px solid #FFFFFF`,
                    cursor: "pointer",
                  }}
                >
                  <InstagramIcon sx={{ color: "#FFFFFF !important" }} fontSize="small" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 1,
                    border: `1px solid #FFFFFF`,
                    cursor: "pointer",
                  }}
                >
                  <LinkedinIcon sx={{ color: "#FFFFFF !important" }} fontSize="small" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography variant="h4" sx={{ color: "#FFFFFF !important" }} fontSize={24} fontWeight="normal" mb={1}>
                Company
              </Typography>
              <Box mt={1} mb={2}>
                {companyOption.map((item, ind) => (
                  <Box
                    key={ind}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      py: 1,
                      borderRadius: 1,
                      px: 2,
                      cursor: "pointer",
                      "&:hover": {
                        background: darken("#011C3A", 0.2),
                      },
                    }}
                  >
                    <Icon sx={{ color: "#FFFFFF !important" }}>navigate_next</Icon>
                    <Typography variant="body2" sx={{ color: "#FFFFFF !important" }} ml={1}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography variant="h4" sx={{ color: "#FFFFFF !important" }} fontSize={24} fontWeight="normal" mb={1}>
                Useful Links
              </Typography>
              <Box mt={1} mb={2}>
                {usefulLink.map((item, ind) => (
                  <Box
                    key={ind}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      py: 1,
                      borderRadius: 1,
                      px: 2,
                      cursor: "pointer",
                      "&:hover": {
                        background: darken("#011C3A", 0.2),
                      },
                    }}
                  >
                    <Icon sx={{ color: "#FFFFFF !important" }}>navigate_next</Icon>
                    <Typography variant="body2" sx={{ color: "#FFFFFF !important" }} ml={1}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography variant="h4" sx={{ color: "#FFFFFF !important" }} fontSize={24} fontWeight="normal" mb={1}>
                Newsletter
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFFFFF !important" }} my={2}>
                Sign Up for the latest news
              </Typography>
              <TextField
                size="small"
                name="email"
                label="Email"
                placeholder="Your Email"
                variant="outlined"
                fullWidth
                onChange={handleMailChange}
                value={subscribeMail}
                inputProps={{
                  style: {
                    paddingLeft: 8, 
                    color: "#FFFFFF !important",
                    borderColor: "#FFFFFF !important",
                  },
                }}
                InputProps={{
                  style: {
                    paddingLeft: 15,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon sx={{ color: "#FFFFFF !important" }} fontSize="small">
                        email
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" fullWidth onClick={handleSubscribeMail}>
                SUBSCRIBE
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer2;
