
import { Flex } from '@chakra-ui/react';
// import Card from 'dashboard/components/card/Card';
import React from 'react';
import Banner from 'dashboard/views/admin/main/ecommerce/overviewProduct/components/Banner';
import SearchTableOverview from 'dashboard/views/admin/main/ecommerce/overviewProduct/components/SearchTableOverview';
import { columnsDataOverview } from "dashboard/views/admin/main/ecommerce/overviewProduct/variable/columnsDataOverview";
import tableDataOverview from "dashboard/views/admin/main/ecommerce/overviewProduct/variable/tableDataOverview.json";

export default function ProductOverview() {
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Banner />
			<SearchTableOverview tableData={tableDataOverview} columnsData={columnsDataOverview} />
		</Flex>
	);
}
