import Pricing1 from "./Pricing1";
import Pricing2 from "./Pricing2";
import Pricing3 from "./Pricing3";

const pricing = {
    1: Pricing1,
    2: Pricing2,
    3: Pricing3
};

export default pricing;