import Contact1 from "./Contact1";
import Contact2 from "./Contact2";
import Contact3 from "./Contact3";
import Contact4 from "./Contact4";

const contactus = {
    1: Contact1,
    2: Contact2,
    3: Contact3,
    4: Contact4
};

export default contactus;

