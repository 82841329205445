// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "dashboard/components/card/Card.js";
import InputField from "dashboard/components/fields/InputField";
import TextField from "dashboard/components/fields/TextField";
import SwitchField from "dashboard/components/fields/SwitchField";
import Dropzone from 'dashboard/components/upload';
import React, { useEffect, useState } from "react";
import { API } from 'utilities/api';
import { toast } from "react-toastify";
import { MdOutlineCloudUpload } from 'react-icons/md';

export default function StageDetails(props) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const { stageLayout, stage, selectedBrand } = props
  const [formData, setFormData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value ? e.target.value : e.target.checked });
  };
  // Chakra Color Mode
  useEffect(() => {
    setFormData(stageLayout)
  }, [stageLayout])
  const handleCreateStageData = () => {
    const newFormData = new FormData();
    newFormData.append('data', JSON.stringify({ stage: "stagelayout", stage_name: stage, brand_id: selectedBrand, ...formData }));
    if (imageFile && imageFile !== null) {
      newFormData.append('file', imageFile);
    }
    API.insertUpdateItemToStage(newFormData).then((response) => {
      console.log(response);
      toast.success('Your data has been updated!');
    }).catch((error) => {
      toast.error(error);
    });
  };

  const handleImageUpload = (file) => {
    setImageFile(file);
  };

  return (
    <>
      <FormControl>
        <Card mb='20px'>
          <Flex
            align={{ lg: "center" }}
            justify={{ base: "space-between" }}
            w='100%'
            px='20px'
            mb='20px'>
            <Flex direction='column'>
              <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
                {stage.charAt(0).toUpperCase() + stage.slice(1)} Details
              </Text>
              <Text fontSize='md' color={textColorSecondary}>
                Here you can change your {stage} details
              </Text>
            </Flex>
            <Flex cursor='pointer' h='max-content' w='max-content'>
              <Flex align={{ lg: "center" }} gap='3'>
                <SwitchField
                  id='published'
                  label="Published"
                  onChange={(e) => handleInputChange(e, 'published')}
                  isChecked={formData?.published || false}
                />
                  <Dropzone
                    onFileUploaded={handleImageUpload} // Callback to handle logo upload
                    file={imageFile}
                    h='50'
                    mt='10px'
                    content={
                      <Box>
                        <Icon
                          as={MdOutlineCloudUpload}
                          w="40px"
                          h="40px"
                          color={textColorPrimary}
                        />
                      </Box>
                    }
                  />
                <Button
                  fontSize='14px'
                  variant='brand'
                  borderRadius='16px'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  // mb='24px'
                  mt='10px'
                  onClick={handleCreateStageData}
                >
                  Update
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <FormControl>
            <SimpleGrid
              columns={{ sm: 1, md: 1 }}
              spacing={{ base: "20px", xl: "20px" }}>
              <Flex direction='column' gap={'20px'}>
                <InputField
                  mb='0px'
                  me='30px'
                  id='title'
                  label='Title'
                  value={formData?.title || stage}
                  onChange={(e) => handleInputChange(e, 'title')}
                  placeholder={stage.charAt(0).toUpperCase() + stage.slice(1)}
                />
                <TextField
                  id='description'
                  label='Description'
                  value={formData?.description || ''}
                  onChange={(e) => handleInputChange(e, 'description')}
                  placeholder={"Your description here"}
                />
              </Flex>
            </SimpleGrid>
          </FormControl>
        </Card>
      </FormControl>
    </>
  );
}
