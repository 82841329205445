import React, { useEffect, useState } from 'react';
import portfolios from './model';

const PortfoliosComponent = (props) => {
  const { websiteDetails } = props;
  const [portfolioNumber, setPortfolioNumber] = useState(websiteDetails.portfolioNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setPortfolioNumber(websiteDetails.portfolioNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const PortfolioComponent = portfolios[portfolioNumber];
  return (
    <div>
      {PortfolioComponent ? <PortfolioComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setPortfolioNumber(portfolioNumber - 1 > 0 ? portfolioNumber - 1 : 1)}> - </button>
          <span>Version {portfolioNumber}</span>
          <button onClick={() => setPortfolioNumber(portfolios[portfolioNumber + 1] ? portfolioNumber + 1 : portfolioNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default PortfoliosComponent;
