import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  Icon,
  Typography,
  Container,
  Box,
  useTheme,
  Divider,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Services1 = (porps) => {
  const { landingDetails, layoutDetails } = porps;
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    let intervalId;
    if (!isHovered) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % landingDetails?.length );
      }, 3000); // Adjust interval as needed
    }
    return () => clearInterval(intervalId);
  }, [isHovered, landingDetails?.length]);

  const getVisibleServices = (index) => {
    const visibleServices = [];
    for (let i = 0; i < 3; i++) {
      visibleServices.push(landingDetails[(index + i) % landingDetails?.length]);
    }
    return visibleServices;
  };

  return (
    <Box id="service">
      <Container>
        <Box textAlign="center" mb={8}>
          <Typography variant="h3" sx={{  color: 'primary.main', marginBottom: 4 }}>
          <Divider>{layoutDetails?.title}</Divider>
        </Typography>
          <Typography variant="body1" sx={{ maxWidth: 600, mx: "auto" }}>
            {layoutDetails?.description}
          </Typography>
        </Box>

        <AutoPlaySwipeableViews
          interval={3000}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          index={currentIndex}
          onChangeIndex={(index) => setCurrentIndex(index)}
          enableMouseEvents
          resistance
        >
          {Array.from({ length: Math.ceil(landingDetails?.length - 1 / 3) }).map((_, pageIndex) => (
            <Box
              key={pageIndex}
              sx={{
                display: "flex",
                justifyContent: "center",
                px: 1,
              }}
            >
              <Grid container spacing={3}>
                {getVisibleServices(pageIndex * 3).map((service, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        borderTop: `2px solid ${theme.palette.divider}`,
                        borderBottom: `2px solid ${theme.palette.divider}`, // Ensure the bottom border line is visible
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Stronger shadow effect
                        transition: "border-top-color 0.3s, box-shadow 0.3s",
                        "&:hover": {
                          borderTopColor: theme.palette.primary.main,
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)", // Stronger shadow on hover
                          "& .MuiIcon-root": {
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <Box textAlign="center" mb={4}>
                          <Icon
                            sx={{
                              fontSize: 64,
                              transition: "color 0.3s",
                              color: theme.palette.text.primary,
                            }}
                          >
                            {service?.icon}
                          </Icon>
                        </Box>
                        <Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: "light",
                              mb: 2,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {service?.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {service?.description}
                          </Typography>
                        </Box>
                        <Box pt={4} textAlign="center">
                          <Button
                            variant="outlined"
                            sx={{
                              color: theme.palette.primary.main,
                              borderColor: theme.palette.primary.main,
                            }}
                          >
                            READ MORE
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Container>
    </Box>
  );
};

export default Services1;
