import React, { useState } from 'react';
import { API } from 'utilities/api';
import {
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
    SimpleGrid,
    Icon,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import DialogModal from "dashboard/layouts/auth/types/popup";
import { toast } from "react-toastify";
import InputField from 'dashboard/components/fields/InputField';
import Dropzone from 'dashboard/components/upload';
import InformationDetails from 'dashboard/pages/brand/settings/components/InformationDetails';
// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';

const CreateModal = (props) => {
    const { initData , fetchBrands, add, setShowCreateModal} = props;
    const textColor = useColorModeValue("secondaryGray.900", "white");
    // const textColorSecondary = "gray.400";
    // const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
    // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");

    // Initialize state with props data
    const [logoFile, setLogoFile] = useState(null); // State to store uploaded logo file
    const [formData, setFormData] = useState({
        name: initData?.name || '',
        domain: initData?.brandDomain || '',
        brand_color: initData?.brand_color || '',
        city: initData?.city || '',
        country: initData?.country || '',
        industry: initData?.industry || '',
        contract_number: initData?.contract_number || '',
        number_of_employee: initData?.number_of_employee || '',
        bank_info: {
            account_number: '',
            IBAN: '',
            SWIFT: '',
            name: '',
            bankName: '',
        }
    });

    const handleLogoUpload = (file) => {
        // Handle logo upload and set the logo file state
        setLogoFile(file);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('bank_info')) {
            // Handle nested bank_info object fields
            const fieldName = name.split('.')[1]; // Extract field name from bank_info.fieldName
            setFormData({
                ...formData,
                bank_info: {
                    ...formData.bank_info,
                    [fieldName]: value,
                }
            });
        } else {
            // Handle other form fields
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const finalFormData = new FormData();
        finalFormData.append('data', JSON.stringify({ ...formData}));

        // Append all fields from formData state
        // Object.entries(formData).forEach(([key, value]) => {
        //     if (typeof value === 'object' && value !== null) {
        //         // Flatten nested bank_info object into FormData
        //         Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        //             finalFormData.append(`bank_info.${nestedKey}`, nestedValue);
        //         });
        //     } else {
        //         finalFormData.append(key, value);
        //     }
        // });

        // Append logo file to FormData if it exists
        if (logoFile) {
            finalFormData.append('file', logoFile);
        }

        // You can handle the formData submission here
        API.submitBrandData(finalFormData)
            .then((response) => {
                toast.success(response?.message || response);
                // Handle any redirection or further actions after successful submission
                fetchBrands();
                setShowCreateModal(false);
                // window?.location?.reload();
            })
            .catch((error) => {
                toast.error(error?.message || error);
            });
    };

    return (
        <>
            <DialogModal
                title="Create Brand"
                isOpenAction={formData?.name ? false : true}
                textColor={textColor}
                onCloseAction={() => add ? setShowCreateModal(false) : null}
                closedNotAllowed={!add}
                key="create"
            >
                <InformationDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    handleLogoUpload={handleLogoUpload}
                    logoFile={logoFile}
                />
            </DialogModal>
        </>
    );
};

export default CreateModal;
