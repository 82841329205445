import React, { useEffect, useState } from 'react';
import conferences from './model';

const ConferencesComponent = (props) => {
  const { websiteDetails } = props;
  const [conferenceNumber, setConferenceNumber] = useState(websiteDetails.conferenceNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setConferenceNumber(websiteDetails.conferenceNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const ConferenceComponent = conferences[conferenceNumber];
  return (
    <div>
      {ConferenceComponent ? <ConferenceComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setConferenceNumber(conferenceNumber - 1 > 0 ? conferenceNumber - 1 : 1)}> - </button>
          <span>Version {conferenceNumber}</span>
          <button onClick={() => setConferenceNumber(conferences[conferenceNumber + 1] ? conferenceNumber + 1 : conferenceNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default ConferencesComponent;
