import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  Container,
  Box,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({ darkMode, handleThemeChange }) => {
  const theme = useTheme();
  const [isClosed, setIsClosed] = useState(true);

  const toggleMenu = () => {
    setIsClosed(!isClosed);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        transition: "background-color 0.3s ease-in-out",
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)", // For Safari
      }}
      // className={isClosed ? "closed" : ""}
    >
      <Container maxWidth="lg">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              component="img"
              src="/assets/images/logo/logo.png"
              alt="Logo"
              sx={{
                height: 40,
                filter: darkMode ? "drop-shadow(0 0 5px white)" : "none",
              }}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }}>
            <List sx={{ display: "flex", flexDirection: "row", padding: 0 }}>
              <ListItem button >
                <ListItemText primary="Home" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
              <ListItem button onClick={toggleMenu}>
                <ListItemText primary="Service" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
              <ListItem button onClick={toggleMenu}>
                <ListItemText primary="Projects" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
              <ListItem button onClick={toggleMenu}>
                <ListItemText primary="Contact" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
            </List>
          </Box>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={handleThemeChange} />}
            label="Dark Mode"
            sx={{ marginRight: 2 }}
          />
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            sx={{ display: { md: "none" } }}
          >
            {isClosed ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </Toolbar>
        {!isClosed && (
          <Box sx={{ display: { xs: "block", md: "none" }, paddingTop: 1 }}>
            <List component="nav">
            <ListItem button >
                <ListItemText primary="Home" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
              <ListItem button onClick={toggleMenu}>
                <ListItemText primary="Service" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
              <ListItem button onClick={toggleMenu}>
                <ListItemText primary="Projects" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
              <ListItem button onClick={toggleMenu}>
                <ListItemText primary="Contact" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
            </List>
          </Box>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
