import React, { useEffect, useState } from 'react';
import headers from './model';

const HeadersComponent = (props) => {
  const { websiteDetails, darkMode, handleThemeChange, componentsOrder } = props;
  const [headerNumber, setHeaderNumber] = useState(websiteDetails.headersNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);
  useEffect(() => {
    setHeaderNumber(websiteDetails.headersNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);
  const HeaderComponent = headers[headerNumber];
  return (
    <div>
      {HeaderComponent ? <HeaderComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          {/* <button onClick={() => setHeaderNumber(headerNumber - 1 > 0 ? headerNumber - 1 : 1)}> - </button> */}
          {/* <span>Version {headerNumber}</span>
          <button onClick={() => setHeaderNumber(headers[headerNumber + 1] ? headerNumber + 1 : headerNumber)}> + </button> */}
        </div>
      )}
    </div>
  );
};

export default HeadersComponent;
