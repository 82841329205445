import React from "react";
import { Grid, Icon, Typography, useMediaQuery, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Story1 = (porps) => {
  const { landingDetails, layoutDetails } = porps;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const ourVision = [
    "Custom Application Development: Tailored solutions to meet your unique business needs.",
    "Web Development: Creating dynamic, user-friendly web applications.",
    "IT Support: Comprehensive services to keep your operations running smoothly.",
    "DevOps and Network Infrastructure: Enhancing efficiency and reliability with expert DevOps and robust network infrastructure solutions."];
  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, padding: theme.spacing(4),  marginTop: theme.spacing(10) }}>
      <Box sx={{ maxWidth: 1200, margin: '0 auto' }}>
        <Grid container spacing={isMobile ? 4 : 10} justifyContent="space-between">
          <Grid item sm={6} xs={12}>
            <Box
              component="img"
              src={layoutDetails?.image}
              alt="graduate"
              sx={{ maxHeight: 700, maxWidth: '100%', borderRadius: 20 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="h1" sx={{ marginTop: 0, fontSize: 38, fontWeight: 'normal', marginBottom: 2, display: 'inline-block', color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary }}>
            {layoutDetails?.title}
            </Typography>
            <Typography variant="body1" sx={{ my: 2, maxWidth: 400, color: theme.palette.text.primary }}>
              {layoutDetails?.description}
            </Typography>
            {ourVision.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
                <Icon color="primary" sx={{ marginRight: 1 }}>
                  done
                </Icon>
                <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                  {item}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Story1;
