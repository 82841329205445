import React from "react";
import { Box, useTheme } from "@mui/material";
import ParticlesComponent from "./ParticleComponent";

const Banner12 = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      component="section"
      id="banner"
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh", // Full screen height
        overflow: "hidden",
        backgroundImage: isDarkMode
          ? 'url("/assets/images/common/1-dark.png")'
          : 'url("/assets/images/common/1.png")',
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#ffffff", // Text color
      }}
    >
      <ParticlesComponent />
    </Box>
  );
};

export default Banner12;
