import React, { useEffect, useState } from 'react';
import marketings from './model';

const MarketingsComponent = (props) => {
  const { websiteDetails } = props;
  const [marketingNumber, setMarketingNumber] = useState(websiteDetails.marketingNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setMarketingNumber(websiteDetails.marketingNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const MarketingComponent = marketings[marketingNumber];
  return (
    <div>
      {MarketingComponent ? <MarketingComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setMarketingNumber(marketingNumber - 1 > 0 ? marketingNumber - 1 : 1)}> - </button>
          <span>Version {marketingNumber}</span>
          <button onClick={() => setMarketingNumber(marketings[marketingNumber + 1] ? marketingNumber + 1 : marketingNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default MarketingsComponent;
