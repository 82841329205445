import React, { useEffect, useState } from 'react';
import { API } from 'utilities/api';
import {
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
    SimpleGrid,
    Icon,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import InputField from 'dashboard/components/fields/InputField';
import Dropzone from 'dashboard/components/upload';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';

const InformaitionDetails = (props) => {
    const { initData, fetchData, fetchBrands, add, setShowCreateModal } = props;
    const textColor = useColorModeValue("secondaryGray.900", "white");
    // const textColorSecondary = "gray.400";
    // const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
    // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");

    // Initialize state with props data
    const [logoFile, setLogoFile] = useState(null); // State to store uploaded logo file
    const [formData, setFormData] = useState({
        name: '',
        domain: '',
        brand_color: '',
        city: '',
        country: '',
        industry: '',
        contract_number: '',
        number_of_employee: '',
        bank_info: {
            account_number: '',
            IBAN: '',
            SWIFT: '',
            name: '',
            bankName: '',
        }
    });

    useEffect(() => {
        if (initData) {
            setFormData(initData);
            if (initData?.brand_logo_upload_path) {
                setLogoFile(initData?.brand_logo_upload_path);
            } else {
                setLogoFile(null);
            }
        }  
    }, [initData])

    const handleLogoUpload = (file) => {
        // Handle logo upload and set the logo file state
        setLogoFile(file);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('bank_info')) {
            // Handle nested bank_info object fields
            const fieldName = name.split('.')[1]; // Extract field name from bank_info.fieldName
            setFormData({
                ...formData,
                bank_info: {
                    ...formData?.bank_info,
                    [fieldName]: value,
                }
            });
        } else {
            // Handle other form fields
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const finalFormData = new FormData();
        
        // Append logo file to FormData if it exists
        if (logoFile) {
            finalFormData.append('file', logoFile);
        }

        // You can handle the formData submission here
        if (add) {
            finalFormData.append('data', JSON.stringify({ ...formData }));
        API.submitBrandData(finalFormData)
            .then((response) => {
                toast.success(response?.message || response);
                // Handle any redirection or further actions after successful submission
                fetchBrands();
                setShowCreateModal(false);
                // window?.location?.reload();
            })
            .catch((error) => {
                toast.error(error?.message || error);
            });
        } else {
            finalFormData.append('data', JSON.stringify({'brand_id': initData?._id, ...formData }));
            API.updateBrandData(finalFormData)
            .then((response) => {
                toast.success(response?.message ? response?.message : "Brand data updated successfully");
                // Handle any redirection or further actions after successful submission
                fetchData();
            })
            .catch((error) => {
                toast.error(error?.message || error);
            });
        }
    };

    return (
        <>
            <Flex direction="column" align="center" position="relative" gap="20px">
                <Box width="100%">
                    <Tabs variant='unstyled' position='relative'>
                        <TabList>
                            <Tab _selected={{ borderTopLeftRadius: 'md', borderTopRightRadius: 'md', boxShadow: "4px -4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(0, 0, 0, 0.1)" }}>Information</Tab>
                            <Tab _selected={{ borderTopLeftRadius: 'md', borderTopRightRadius: 'md', boxShadow: "4px -4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(0, 0, 0, 0.1)" }}>Logo</Tab>
                            <Tab _selected={{ borderTopLeftRadius: 'md', borderTopRightRadius: 'md', boxShadow: "4px -4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(0, 0, 0, 0.1)" }}>Bank Info</Tab>
                        </TabList>
                        <TabPanels boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}>
                            <TabPanel>
                                <form onSubmit={handleSubmit}>
                                    <Flex direction="column" w="100%">
                                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                            <Stack direction="column" gap="20px">
                                                <InputField
                                                    mb="0px"
                                                    id="name"
                                                    name="name"
                                                    value={formData?.name}
                                                    onChange={handleInputChange}
                                                    placeholder="Brand Name"
                                                    label="Brand Name"
                                                    required
                                                />
                                                <InputField
                                                    mb="0px"
                                                    id="domain"
                                                    name="domain"
                                                    value={formData?.domain}
                                                    onChange={handleInputChange}
                                                    placeholder="https://example.com"
                                                    label="Brand Domain"
                                                    required
                                                />
                                                <InputField
                                                    mb="0px"
                                                    id="brand_color"
                                                    name="brand_color"
                                                    value={formData?.brand_color}
                                                    onChange={handleInputChange}
                                                    placeholder="Brand Color"
                                                    label="Brand Color"
                                                />
                                                <InputField
                                                    mb="0px"
                                                    id="city"
                                                    name="city"
                                                    value={formData?.city}
                                                    onChange={handleInputChange}
                                                    placeholder="City"
                                                    label="City"
                                                />
                                            </Stack>
                                            <Stack direction="column" gap="20px">
                                                <InputField
                                                    mb="0px"
                                                    id="country"
                                                    name="country"
                                                    value={formData?.country}
                                                    onChange={handleInputChange}
                                                    placeholder="Country"
                                                    label="Country"
                                                />
                                                <InputField
                                                    mb="0px"
                                                    id="industry"
                                                    name="industry"
                                                    value={formData?.industry}
                                                    onChange={handleInputChange}
                                                    placeholder="Industry"
                                                    label="Industry"
                                                />
                                                <InputField
                                                    mb="0px"
                                                    id="contract_number"
                                                    name="contract_number"
                                                    value={formData?.contract_number}
                                                    onChange={handleInputChange}
                                                    placeholder="Contract Number"
                                                    label="Contract Number"
                                                />
                                                <InputField
                                                    mb="0px"
                                                    id="number_of_employee"
                                                    name="number_of_employee"
                                                    value={formData?.number_of_employee}
                                                    onChange={handleInputChange}
                                                    placeholder="Number of Employees"
                                                    label="Number of Employees"
                                                />
                                            </Stack>
                                        </SimpleGrid>
                                        <Flex justify="center" mt="24px">

                                        </Flex>
                                    </Flex>
                                </form>
                            </TabPanel>
                            <TabPanel>
                                <Dropzone
                                    onFileUploaded={handleLogoUpload} // Callback to handle logo upload
                                    file={logoFile}
                                    content={
                                        <Box>
                                            <Icon
                                                as={MdOutlineCloudUpload}
                                                w="80px"
                                                h="80px"
                                                color={textColor}
                                            />
                                            <Text
                                                mx="auto"
                                                mb="12px"
                                                fontSize="lg"
                                                fontWeight="700"
                                                whiteSpace="pre-wrap"
                                                color={textColor}
                                            >
                                                Drop your logo here, or{' '}
                                                <Text
                                                    as="span"
                                                    fontSize="lg"
                                                    fontWeight="700"
                                                    color={textColorBrand}
                                                >
                                                    browse
                                                </Text>
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight="500"
                                                color="secondaryGray.500"
                                            >
                                                PNG, JPG and GIF files are allowed
                                            </Text>
                                        </Box>
                                    }
                                />
                            </TabPanel>
                            <TabPanel>
                                <Flex direction="column" w="100%">
                                    <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                        <Stack direction="column" gap="20px">
                                            <InputField
                                                mb="0px"
                                                id="account_number"
                                                name="bank_info.account_number"
                                                value={formData?.bank_info?.account_number}
                                                onChange={handleInputChange}
                                                placeholder="Account Number"
                                                label="Account Number"
                                                required
                                            />
                                            <InputField
                                                mb="0px"
                                                id="IBAN"
                                                name="bank_info.IBAN"
                                                value={formData?.bank_info?.IBAN}
                                                onChange={handleInputChange}
                                                placeholder="IBAN"
                                                label="IBAN"
                                                required
                                            />
                                            <InputField
                                                mb="0px"
                                                id="SWIFT"
                                                name="bank_info.SWIFT"
                                                value={formData?.bank_info?.SWIFT}
                                                onChange={handleInputChange}
                                                placeholder="SWIFT"
                                                label="SWIFT"
                                                required
                                            />
                                        </Stack>
                                        <Stack direction="column" gap="20px">
                                            <InputField
                                                mb="0px"
                                                id="name"
                                                name="bank_info.name"
                                                value={formData?.bank_info?.name}
                                                onChange={handleInputChange}
                                                placeholder="Name"
                                                label="Name"
                                                required
                                            />
                                            <InputField
                                                mb="0px"
                                                id="bankName"
                                                name="bank_info.bankName"
                                                value={formData?.bank_info?.bankName}
                                                onChange={handleInputChange}
                                                placeholder="Bank Name"
                                                label="Bank Name"
                                                required
                                            />
                                        </Stack>
                                    </SimpleGrid>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </Box>
                <Button
                    type="submit"
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Flex>
        </>
    );
};

export default InformaitionDetails;
