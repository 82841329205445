import Marketing1 from "./Marketing1";
import Marketing2 from "./Marketing2";
import Marketing3 from "./Marketing3";

const marketing = {
    1: Marketing1,
    2: Marketing2,
    3: Marketing3
};

export default marketing;