import Subscription1 from "./Subscription1";
import Subscription2 from "./Subscription2";
import Subscription3 from "./Subscription3";
import Subscription4 from "./Subscription4";

const subscriptions = {
    1: Subscription1,
    2: Subscription2,
    3: Subscription3,
    4: Subscription4
};

export default subscriptions;