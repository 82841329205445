import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Avatar,
  Typography,
  Box,
  Container,
  useTheme,
  CardContent,
  useMediaQuery,
  Divider,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Recommendation3 = (props) => {
  const { landingDetails, layoutDetails } = props;
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    let intervalId;
    if (!isHovered && landingDetails?.length > 3) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % landingDetails?.length);
      }, 3000); // Adjust interval as needed
    }
    return () => clearInterval(intervalId);
  }, [isHovered]);

  const itemsPerPage = isMdUp ? 3 : isSmUp ? 2 : 1;

  // Get the next items based on currentIndex and itemsPerPage
  const getVisibleCards = (index) => {
    const visibleCards = [];
    for (let i = 0; i < itemsPerPage; i++) {
      visibleCards.push(landingDetails[(index + i) % landingDetails?.length]);
    }
    return visibleCards;
  };

  return (
    <Box component="section" sx={{ py: 8 }} id="recommendation3">
      <Container>
        <Box textAlign="center" mb={8}>
        <Typography variant="h3" sx={{  color: 'primary.main', marginBottom: 4 }}>
          <Divider>{layoutDetails?.title}</Divider>
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: 600, mx: "auto" }}>
            {layoutDetails?.description}
          </Typography>
        </Box>

        <AutoPlaySwipeableViews
          interval={3000}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          index={currentIndex}
          onChangeIndex={(index) => setCurrentIndex(index)}
          enableMouseEvents
          resistance
        >
          {Array.from({ length: Math.ceil(landingDetails?.length - 1 / itemsPerPage) }).map((_, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                justifyContent: "center",
                px: 1,
              }}
            >
              <Grid container spacing={3}>
                {getVisibleCards(idx * itemsPerPage).map((card, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        borderTop: `2px solid ${theme.palette.divider}`,
                        borderBottom: `2px solid ${theme.palette.divider}`, // Ensure the bottom border line is visible
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Stronger shadow effect
                        transition: "border-top-color 0.3s, box-shadow 0.3s",
                        "&:hover": {
                          borderTopColor: theme.palette.primary.main,
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)", // Stronger shadow on hover
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <Box textAlign="center" mb={4}>
                          <Avatar
                            src={card.image}
                            alt={card.recommender_name}
                            sx={{ width: 64, height: 64 }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "light",
                              mb: 2,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {card.recommender_title}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {card.recommender_description}
                          </Typography>
                        </Box>
                        <Box pt={4} textAlign="center">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{
                              color: theme.palette.text.primary,
                            }}
                          >
                            {card.recommender_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              m: 0,
                              color: theme.palette.text.secondary,
                            }}
                          >
                            {card.recommender_designation}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Container>
    </Box>
  );
};

export default Recommendation3;
