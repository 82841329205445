import React, { useEffect, useState } from 'react';
import gallerys from './model';

const GallerysComponent = (props) => {
  const { websiteDetails } = props;
  const [galleryNumber, setGalleryNumber] = useState(websiteDetails.galleryNumber || 1);
  const [isOwner, setIsOwner] = useState(websiteDetails.isOwner || false);

  useEffect(() => {
    setGalleryNumber(websiteDetails.galleryNumber || 1);
    setIsOwner(websiteDetails.isOwner);
  }, [websiteDetails]);

  const GalleryComponent = gallerys[galleryNumber];
  return (
    <div>
      {GalleryComponent ? <GalleryComponent {...props} /> : <> </>}
      {isOwner && (
        <div>
          <button onClick={() => setGalleryNumber(galleryNumber - 1 > 0 ? galleryNumber - 1 : 1)}> - </button>
          <span>Version {galleryNumber}</span>
          <button onClick={() => setGalleryNumber(gallerys[galleryNumber + 1] ? galleryNumber + 1 : galleryNumber)}> + </button>
        </div>
      )}
    </div>
  );
};

export default GallerysComponent;
