// Chakra imports
import React, { useEffect } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';

function DialogModal(props) {
  //eslint-disable-next-line
  const { children, title, textColor, isOpenAction, onCloseAction, closedNotAllowed, key} = props;

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      console.log('closed');
      onCloseAction();
    },
  });
  
  useEffect(() => {
    if (isOpenAction) {
      onOpen();
    }
  }, []);
  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        key={key}
        closeOnOverlayClick={false} // Prevent closing by clicking on overlay
        scrollBehavior={"inside"}
        // size='lg'
        // pt={{ sm: '125px', lg: '75px' }}

      >
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent borderRadius='15px' overflowY="auto">
          <ModalHeader
            color={textColor}
            fontSize='20px'
            mx={{ base: "auto", lg: "unset" }}
            textAlign={{ base: "center", lg: "left" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            top="0"
            bg="white"
            zIndex="1"
            borderBottom="1px solid"
            borderColor={textColor}
          >
            <Text>{title}</Text>
            {!closedNotAllowed && <ModalCloseButton />}
          </ModalHeader>
          <ModalBody>
            <Box
              // w="100%"
              // h="100%"
              p={{ md: '20px' }}
              pt={{ base: '30px', md: '20px' }}
            >
              {children}
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}

export default DialogModal;
