import { Flex, Image } from '@chakra-ui/react';
import { HSeparator } from 'dashboard/components/separator/Separator';

export function SidebarBrand(props) {

  return (
    <Flex alignItems="center" flexDirection="column">
      <Image src="/assets/images/logo/logo.png" maxH="50px" my="32px" />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
