import React, { useState } from 'react';
import { API } from 'utilities/api';
import { Box, Button, Flex, FormControl, PinInput, PinInputField, Text, useColorModeValue } from "@chakra-ui/react";
import DialogModal from "dashboard/layouts/auth/types/popup";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const VerificationPopup = ({ email, showVerificationCode, setShowVerificationCode }) => {
  const navigate = useNavigate();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");  
  const [code, setCode] = useState('');

  const handleVerify = () => {
    API.verificationMail({ email_address: email, confirmationCode: code }).then((response) => {
      toast.success('Your email has been verified!');
      localStorage.setItem('binnonet_token', response.Token);
      navigate('/dashboard/brand/overview');
    }).catch((error) => {
      toast.error(error);
    });
  };

  const handleResendCode = () => {
    API.resendVerificationMail({ email_address: email }).then((response) => {
      toast.success('Verification code has been sent!');
    }).catch((error) => {
      toast.error(error);
    });
  };

  return (
    <>
        <DialogModal
      title="Verification"
      isOpenAction={showVerificationCode}
      textColor={textColor}
      onCloseAction={() => setShowVerificationCode(false)}
      key="verification"
      cardTop={{ base: "140px", md: "24vh" }}
      cardBottom={{ base: "50px", lg: "auto" }}>
      <Flex
        w='100%'
        maxW='max-content'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        justifyContent='center'
        px={{ base: "25px", md: "0px" }}
        flexDirection='column'>
        <Box me='auto' mb='34px'>
          <Text
            color={textColorSecondary}
            fontSize='md'
            maxW={{ base: "95%", md: "100%" }}
            mx={{ base: "auto", lg: "unset" }}
            textAlign={{ base: "center", lg: "left" }}>
            Enter your Verification code!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "425px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <Flex justify='center'>
              <PinInput mx='auto' otp value={code} onChange={setCode}>
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                />
              </PinInput>
            </Flex>

            <Button
              fontSize='14px'
              variant='brand'
              borderRadius='16px'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              mt='12px'
              onClick={handleVerify}
              >
              Verify
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text
              color={textColorDetails}
              fontWeight='400'
              fontSize='14px'
              mx={{ base: "auto", lg: "unset" }}
              textAlign={{ base: "center", lg: "left" }}>
              Haven't received it?
              <Button color={textColorBrand} as='span' ms='5px' fontWeight='500' onClick={handleResendCode}>
                Resend a new code
              </Button>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DialogModal>
    </>
  );
};

export default VerificationPopup;
