import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  FootersComponent,
  HeadersComponent
} from "../components";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from '../components/theme';
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { CssBaseline } from "@mui/material";
import GlobalCss from "styles/jss/GlobalCss";
import { API } from "utilities/api";

const Layout = ({ websiteDetails, componentsOrder }) => {

  const [darkMode, setDarkMode] = useState(useMediaQuery('(prefers-color-scheme: dark)'));

  // Update the theme in local storage whenever the theme mode changes
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      setDarkMode(theme === 'dark' ? true : false);
    }
  }, []);

  const handleThemeChange = () => {
    localStorage.setItem('theme', darkMode ? 'light' : 'dark');
    setDarkMode(!darkMode);
  };

  const [landingData, setLandingData] = React.useState([]);
  const [layoutData, setLayoutData] = React.useState([]);

  const fetchData = async () => {
    try {
      const domain =  window.location.hostname === 'localhost' ? 'binnonet.com' : window.location.hostname;
      return API.getAllByDomain({ domain: "https://" + domain }).then((response) => {
       return response;
      }).catch((error) => {
        console.log('error', error)
      });
    } catch (error) {
      console.log('error', error)
    }
  };

  React.useEffect(() => {
    fetchData().then((response) => {
      if (response) {
        setLandingData(response);
        setLayoutData(response.stagelayout);
      }
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <GlobalCss>
          <CssBaseline />
          <Scrollbar
            className="h-full-screen scrollable-content"
            options={{ suppressScrollX: true }}
          >
            <HeadersComponent landingData={landingData} layoutData={layoutData} darkMode={darkMode} handleThemeChange={handleThemeChange} websiteDetails={websiteDetails} componentsOrder={componentsOrder} />
            <Outlet context={[landingData, layoutData]}/>
            <FootersComponent landingData={landingData} layoutData={layoutData} websiteDetails={websiteDetails} componentsOrder={componentsOrder} />
          </Scrollbar>
        </GlobalCss>
      </ThemeProvider>
    </>
  );
};

export default Layout;
